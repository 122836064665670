class General
{
    siteTitle = "Eire Digital";

    // host
    siteUrl = "https://api.eiredigital.com";
    apiUrl = this.siteUrl + "/api/pc";
    clientUrl = "https://client.eiredigital.com";
    clientUrlLogin = "https://client.eiredigital.com/login";
    // local
    // siteUrl = "http://127.0.0.1:8000";
    // apiUrl = this.siteUrl + "/api/pc";
    // clientUrl = "http://127.0.0.1:3000";
    // clientUrlLogin = "http://localhost:3000/login";

    // google reCaptcha Key
    reCaptchaKey = '6LdWVOIgAAAAAK6K3Adi92hN5zRHXDacWw7sDPea';

    // token
    localStorageName = "authenticate";

    authorizationToken = "";
    authorizationNameFamily = "";
    user = null;

    // order status
    orderStatusUnpaid = 1;
    orderStatusPaid = 2;
    orderStatusCompleted = 3;
    orderStatusPaymentFailed = 4;
    orderStatusCancelled = 5;

    // login social
    sl__Google__ClientId = "752736865812-jqd54mh1n4qdqbfj2e6b68vvat68aeof.apps.googleusercontent.com";
    sl__Google__Scope = "openid profile email";

    sl__Facebook__AppId = "442532937763030";

    sl__Linkedin__ClientId = "7885dxyap3t3hm";
    sl__Linkedin__ClientSecret = "QRaaA9lXgUiGIJ1k";
    sl__Linkedin__Scope = "r_liteprofile r_emailaddress";

    sl__Twitter__ClientId = "7885dxyap3t3hm";
    sl__Twitter__ClientSecret = "QRaaA9lXgUiGIJ1k";
    sl__Twitter__Scope = "r_liteprofile r_emailaddress";

    sl__Instagram__ClientId = "1723536074666574";
    sl__Instagram__ClientSecret = "875f0f5e47497be82601d34ef4425160";

    sl__Pinterest__ClientId = "1479357";
    sl__Pinterest__ClientSecret = "QRaaA9lXgUiGIJ1k";
    sl__Pinterest__Scope = "r_liteprofile r_emailaddress";

    // init status class
    initStatusClass = (status) =>
    {
        return status.replace(/ /g, '-').toLowerCase();
    };

    // init pageNumber
    initPageNumber = (paginationData) =>
    {
        let pageNumber = paginationData.currentPageNumber;

        if(paginationData.currentPageRows - 1 === 0)
        {
            pageNumber = (paginationData.currentPageNumber > 1) ? pageNumber - 1 : 0;
        }

        return pageNumber;
    };

    // check link
    isLink = (str) =>
    {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        return !!pattern.test(str);
    };

    // check email
    isEmail = (email) =>
    {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };
}

export default new General();
