import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useParams} from "react-router-dom";
import {Button, Container, Grid, InputLabel, TextField} from "@mui/material";
import CountryApi from "../../services/CountryApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Loading from "../../components/Loading/Loading";
import UserApi from "../../services/UserApi";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LogApi from "../../services/LogApi";
import General from "../../utils/General";
import {useAuth} from "../../contexts/Auth";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import "./Profile.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";

//--------------------------------------------------

function Profile(props)
{
    // get page tab active
    let { tabActive } = useParams();

    // set page title & page path
    const pageTitle = Menus.myProfile.title;
    usePage().pageData({title: pageTitle, activeMenu: 'my-profile'});

    const [sectionActiveIndex, setSectionActiveIndex] = useState(tabActive === 'edit' ? 2 : 1);

    const [dataCountries, setDataCountries] = useState([]);

    const [formRegisterUsername, setFormRegisterUsername] = useState(General.user.username);
    const [formRegisterEmail, setFormRegisterEmail] = useState(General.user.email);
    const [formRegisterBillingAddress, setFormRegisterBillingAddress] = useState(General.user.billingAddress);
    const [formRegisterCompanyName, setFormRegisterCompanyName] = useState(General.user.companyName);
    const [formRegisterFirstName, setFormRegisterFirstName] = useState(General.user.firstName);
    const [formRegisterLastName, setFormRegisterLastName] = useState(General.user.lastName);

    const [formRegisterCountry, setFormRegisterCountry] = useState(General.user.countryId);
    const [formRegisterCountryName, setFormRegisterCountryName] = useState(General.user.countryName);

    const [formRegisterPassword, setFormRegisterPassword] = useState("");
    const [formRegisterPasswordRep, setFormRegisterPasswordRep] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordRepType, setPasswordRepType] = useState("password");
    const [isStepOne, setIsStepOne] = useState(true);
    const [hasReferralCode, setHasReferralCode] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [termsConditionDialogOpen, setTermsConditionDialogOpen] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [dataLogs, setDataLogs] = useState(false);

    const auth = useAuth();
    const alertAction = useRef(); // init alert

    // load page data
    const loadData = () =>
    {
        // get data => log
        LogApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataLogs(response.data.list);
                }
            }
        );

        // get data => Country
        CountryApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataCountries(response.data.list);
                }
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // send register data to server
    const register = () =>
    {
        if(formRegisterEmail !== "" && formRegisterFirstName !== "" && formRegisterLastName !== "" && formRegisterCountry !== "0" && ((formRegisterPassword === "" && formRegisterPasswordRep === "") || (formRegisterPassword !== "" && formRegisterPasswordRep !== "" && formRegisterPassword === formRegisterPasswordRep))) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'email': formRegisterEmail,
                    'firstName': formRegisterFirstName,
                    'lastName': formRegisterLastName,
                    'country': formRegisterCountry,
                    'billingAddress': formRegisterBillingAddress,
                    'companyName': formRegisterCompanyName,
                    'password': formRegisterPassword
                };
            UserApi.update(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Profile Updated", "success"); // show success
                        auth.authentication({user: response.data.user, nameFamily: response.data.nameFamily, token: response.data.token});
                    }
                    else
                    {
                        // alertAction.current.showAlert("Incorrect information"); // show error
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetFormResister = () =>
    {
        setFormRegisterEmail("");
        setFormRegisterFirstName("");
        setFormRegisterLastName("");
        // setFormRegisterBusiness("0");
        // setFormRegisterMonthlySpend("0");
        setFormRegisterCountry("0");
        // setFormRegisterReferralCode("");
        setFormRegisterPassword("");
        setFormRegisterPasswordRep("");
        setPasswordType("password");
        setPasswordRepType("password");
        setIsStepOne(true);
        setHasReferralCode(false);
        setAcceptTerms(false);
    };

    //
    const handleBtnSectionClick = (index) =>
    {
        setSectionActiveIndex(index);
    };

    //
    return (
        <div className="page-content page-profile">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/my-profile">Profile</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <Container className="page-section-container">
                    <Grid container spacing={4}>
                        <Grid item lg={5} md={12}>
                            <div className="page-section-box">
                                <div className="user-base-data">
                                    <div className={General.user.isActive ? 'account-status' : 'account-status inactive'}><span className="title">Account Status:</span> {General.user.userStatusTitle}</div>
                                    <div className="date">Member since {General.user.registerDate}</div>
                                    <div className="date">Last Visited on {General.user.lastVisited}</div>
                                </div>

                                <div className="content">
                                    <Button className="btn-section" fullWidth={true} variant={sectionActiveIndex === 1 ? 'contained' : 'outlined'} onClick={() => handleBtnSectionClick(1)}>See Complete Profile</Button>
                                    <Button className="btn-section" fullWidth={true} variant={sectionActiveIndex === 2 ? 'contained' : 'outlined'} onClick={() => handleBtnSectionClick(2)}>Edit Profile/Change Password</Button>
                                    {/*<Button className="btn-section" fullWidth={true} variant={sectionActiveIndex === 3 ? 'contained' : 'outlined'} onClick={() => handleBtnSectionClick(3)}>Check Usage Logs</Button>*/}
                                </div>
                            </div>
                        </Grid>


                        <Grid item lg={7} md={12}>
                            <div className="page-section-box">
                                <div className="section-title">
                                    {(() =>
                                    {
                                        switch(sectionActiveIndex)
                                        {
                                            case 1: return <>Your Complete Profile</>;
                                            case 2: return <>Edit Your Profile</>;
                                            case 3: return <>Your Usage Logs</>;
                                            default: return null;
                                        }
                                    })()}
                                </div>

                                <div className="content">
                                    {(() =>
                                    {
                                        switch(sectionActiveIndex)
                                        {
                                            case 1:
                                                return (
                                                    <div className="section-user-data">
                                                        <div className="item"><span className="title">Username:</span> {formRegisterUsername}</div>
                                                        <div className="item"><span className="title">Full Name:</span> {formRegisterFirstName} {formRegisterLastName}</div>
                                                        <div className={General.user.isActive ? 'item active' : 'item inactive'}><span className="title">User Level:</span> {General.user.userStatusTitle}</div>
                                                        <div className="item"><span className="title">Email:</span> {formRegisterEmail}</div>
                                                        <div className="item"><span className="title">Billing Address:</span> {formRegisterBillingAddress}</div>
                                                        <div className="item"><span className="title">Company Name:</span> {formRegisterCompanyName}</div>
                                                        <div className="item"><span className="title">Country of Residence:</span> {formRegisterCountryName}</div>
                                                    </div>
                                                );

                                            case 2:
                                                return (
                                                    <>
                                                        <div className="form-box">
                                                            <div className="form-container">
                                                                <Grid container spacing={2} className="form-content">
                                                                    <Grid item sm={12} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-username">Username</InputLabel>
                                                                        <TextField fullWidth={true} className="form-input" id="form-register-username" disabled="disabled" value={formRegisterUsername} />
                                                                    </Grid>

                                                                    <Grid item sm={6} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-first-name">First Name</InputLabel>
                                                                        <TextField fullWidth={true} className="form-input" id="form-register-first-name" placeholder="Enter First Name" value={formRegisterFirstName} onChange={(e) => setFormRegisterFirstName(e.target.value)} />
                                                                    </Grid>

                                                                    <Grid item sm={6} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-last-name">Last Name</InputLabel>
                                                                        <TextField fullWidth={true} className="form-input" id="form-register-last-name" placeholder="Enter Last Name" value={formRegisterLastName} onChange={(e) => setFormRegisterLastName(e.target.value)} />
                                                                    </Grid>

                                                                    <Grid item sm={6} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-password">New Password</InputLabel>
                                                                        <TextField fullWidth={true} className="form-input" id="form-register-password" placeholder="******" type={passwordType} autoComplete="current-password" value={formRegisterPassword} onChange={(e) => setFormRegisterPassword(e.target.value)} />
                                                                        {passwordType === 'password' ? <RemoveRedEyeOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('text')} /> : <VisibilityOffOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('password')} />}
                                                                    </Grid>

                                                                    <Grid item sm={6} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-password-rep">Confirm Password</InputLabel>
                                                                        <TextField fullWidth={true} className="form-input" id="form-register-password-rep" placeholder="******" type={passwordRepType} autoComplete="current-password" value={formRegisterPasswordRep} onChange={(e) => setFormRegisterPasswordRep(e.target.value)} />
                                                                        {passwordRepType === 'password' ? <RemoveRedEyeOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordRepType('text')} /> : <VisibilityOffOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordRepType('password')} />}
                                                                    </Grid>

                                                                    <Grid item sm={12} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-email">Email</InputLabel>
                                                                        <TextField fullWidth={true} className="form-input" id="form-register-email" placeholder="Enter Email address" value={formRegisterEmail} onChange={(e) => setFormRegisterEmail(e.target.value)} />
                                                                    </Grid>

                                                                    <Grid item sm={12} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-billing-address">Billing Address</InputLabel>
                                                                        <TextField multiline={true} fullWidth={true} className="form-input" id="form-register-billing-address" value={formRegisterBillingAddress} onChange={(e) => setFormRegisterBillingAddress(e.target.value)} />
                                                                    </Grid>

                                                                    <Grid item sm={12} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-company-name">Company Name</InputLabel>
                                                                        <TextField fullWidth={true} className="form-input" id="form-register-company-name" placeholder="Enter Company Name" value={formRegisterCompanyName} onChange={(e) => setFormRegisterCompanyName(e.target.value)} />
                                                                    </Grid>

                                                                    <Grid item sm={12} xs={12}>
                                                                        <InputLabel shrink className="form-label" htmlFor="form-register-country">Country Of Residence</InputLabel>
                                                                        <Select className="form-select" id="form-register-country" value={formRegisterCountry} onChange={(e) => setFormRegisterCountry(e.target.value)}>
                                                                            <MenuItem value="0">Your Country...</MenuItem>
                                                                            {
                                                                                dataCountries !== null && dataCountries.length > 0 ?
                                                                                    dataCountries.map
                                                                                    (
                                                                                        (item, index) =>
                                                                                        {
                                                                                            return (
                                                                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                                            );
                                                                                        }
                                                                                    )
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </Select>
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        <div className="m-t-16">
                                                                            <Button onClick={register} color="primary" variant="contained" className="m-r-8" startIcon={<AutorenewIcon />}>Submit Changes</Button>
                                                                            <Button onClick={resetFormResister} color="primary" variant="outlined">Reset Form</Button>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    </>
                                                );

                                            case 3:
                                                return (
                                                    dataLogs !== null && dataLogs.length > 0 ?
                                                        <>
                                                            <div className="section-log-des">Your usage records go back up to 15 previous interactions you made on the app. For a more detailed log, please drop us a mail.</div>

                                                            <table className="section-log-table">
                                                                <thead>
                                                                <tr>
                                                                    <th className="w-60">Sr.no</th>
                                                                    <th>Event</th>
                                                                    <th className="w-180">Date / Time</th>
                                                                </tr>
                                                                </thead>

                                                                <tbody>
                                                                {
                                                                    dataLogs.map
                                                                    (
                                                                        (item, index) =>
                                                                        {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{item.event}</td>
                                                                                    <td>{item.dateTime}</td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </>
                                                        :
                                                        <AlertDataEmpty />
                                                );

                                            default:
                                                return null;
                                        }
                                    })()}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                {/* loading */}
                {formLoading ? <LoadingOver /> : null}

                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>
        </div>
    );
}

export default Profile;
