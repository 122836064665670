import RestApi from "./RestApi.js";

export default
{
    find: async function(params) { return RestApi.rest("/order/list", params); },
    view: async function(params) { return RestApi.rest("/order/view", params); },
    paymentSummery: async function(params) { return RestApi.rest("/order/payment/summery", params); },
    save: async function(params) { return RestApi.rest("/order/save", params); },
    cancel: async function(params) { return RestApi.rest("/order/cancel", params); },
    tagSave: async function(params) { return RestApi.rest("/order/tag/save", params); },
    couponSave: async function(params) { return RestApi.rest("/order/coupon/save", params); },
    fileUpload: async function(params) { return RestApi.restUpload("/order/file/upload", params); },
    fileDownload: async function(params) { return RestApi.restBuffer("/order/file/download", params); },
    stripePaymentCheck: async function(params) { return RestApi.rest("/stripe/payment/check", params); },
    paymentWallet: async function(params) { return RestApi.rest("/order/payment/wallet", params); },
    analyseRequest: async function(params) { return RestApi.rest("/order/analyse-request/save", params); },
}
