import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useParams} from "react-router-dom";
import {Button, Container, Grid, InputAdornment, InputLabel, TextField} from "@mui/material";
import CountryApi from "../../services/CountryApi";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Loading from "../../components/Loading/Loading";
import UserApi from "../../services/UserApi";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LogApi from "../../services/LogApi";
import General from "../../utils/General";
import {useAuth} from "../../contexts/Auth";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import "./AnalyseRequest.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import orderApi from "../../services/OrderApi";
import imageProcess from "../../images/data-analysis.gif";
import imageSuccessfully from "../../images/successfully-done.gif";

//--------------------------------------------------

function AnalyseRequest(props)
{
    // set page title & page path
    const pageTitle = "Analyse Request";
    usePage().pageData({title: pageTitle, activeMenu: 'new-order'});

    const [formAnalyseRequestKeyword, setFormAnalyseRequestKeyword] = useState("");
    const [formAnalyseRequestUrl, setFormAnalyseRequestUrl] = useState("");
    const [formAnalyseRequestCountry, setFormAnalyseRequestCountry] = useState("");
    const [formAnalyseRequestLanguage, setFormAnalyseRequestLanguage] = useState("");
    const [formAnalyseRequestCompetitor1, setFormAnalyseRequestCompetitor1] = useState("");
    const [formAnalyseRequestCompetitor2, setFormAnalyseRequestCompetitor2] = useState("");
    const [formAnalyseRequestCompetitor3, setFormAnalyseRequestCompetitor3] = useState("");
    const [formAnalyseRequestCompetitor4, setFormAnalyseRequestCompetitor4] = useState("");
    const [formAnalyseRequestCompetitor5, setFormAnalyseRequestCompetitor5] = useState("");
    const [formAnalyseRequestCompetitor6, setFormAnalyseRequestCompetitor6] = useState("");
    const [formAnalyseRequestCompetitor7, setFormAnalyseRequestCompetitor7] = useState("");
    const [formAnalyseRequestCompetitor8, setFormAnalyseRequestCompetitor8] = useState("");
    const [formAnalyseRequestCompetitor9, setFormAnalyseRequestCompetitor9] = useState("");
    const [formAnalyseRequestCompetitor10, setFormAnalyseRequestCompetitor10] = useState("");

    // const [formLoading, setFormLoading] = useState(false);

    const [dataCountries, setDataCountries] = useState([]);
    const [dataLanguages, setDataLanguages] = useState(['Afrikaans', 'Albanian', 'Amharic', 'Arabic', 'Armenian', 'Assamese', 'Aymara', 'Azerbaijani', 'Bambara', 'Basque', 'Belarusian', 'Bengali', 'Bhojpuri', 'Bosnian', 'Bulgarian', 'Catalan', 'Cebuano', 'Chichewa', 'Chinese (Simplified)', 'Chinese (Traditional)', 'Corsican', 'Croatian', 'Czech', 'Danish', 'Dhivehi', 'Dogri', 'Dutch', 'English', 'Esperanto', 'Estonian', 'Ewe', 'Filipino', 'Finnish', 'French', 'Frisian', 'Galician', 'Georgian', 'German', 'Greek', 'Guarani', 'Gujarati', 'Haitian Creole', 'Hausa', 'Hawaiian', 'Hebrew', 'Hindi', 'Hmong', 'Hungarian', 'Icelandic', 'Igbo', 'Ilocano', 'Indonesian', 'Irish', 'Italian', 'Japanese', 'Javanese', 'Kannada', 'Kazakh', 'Khmer', 'Kinyarwanda', 'Konkani', 'Korean', 'Krio', 'Kurdish (Kurmanji)', 'Kurdish (Sorani)', 'Kyrgyz', 'Lao', 'Latin', 'Latvian', 'Lingala', 'Lithuanian', 'Luganda', 'Luxembourgish', 'Macedonian', 'Maithili', 'Malagasy', 'Malay', 'Malayalam', 'Maltese', 'Maori', 'Marathi', 'Meiteilon (Manipuri)', 'Mizo', 'Mongolian', 'Myanmar (Burmese)', 'Nepali', 'Norwegian', 'Odia (Oriya)', 'Oromo', 'Pashto', 'Persian', 'Polish', 'Portuguese', 'Punjabi', 'Quechua', 'Romanian', 'Russian', 'Samoan', 'Sanskrit', 'Scots Gaelic', 'Sepedi', 'Serbian', 'Sesotho', 'Shona', 'Sindhi', 'Sinhala', 'Slovak', 'Slovenian', 'Somali', 'Spanish', 'Sundanese', 'Swahili', 'Swedish', 'Tajik', 'Tamil', 'Tatar', 'Telugu', 'Thai', 'Tigrinya', 'Tsonga', 'Turkish', 'Turkmen', 'Twi', 'Ukrainian', 'Urdu', 'Uyghur', 'Uzbek', 'Vietnamese', 'Welsh', 'Xhosa', 'Yiddish', 'Yoruba', 'Zulu']);

    const [sectionActiveIndex, setSectionActiveIndex] = useState(1);
    const [formStepIndex, setFormStepIndex] = useState(1);

    const auth = useAuth();
    const alertAction = useRef(); // init alert

    // load page data
    const loadData = () =>
    {
        // get data => Country
        CountryApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataCountries(response.data.list);
                }
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // check data
    const checkAnalyseRequest = () =>
    {
        switch (formStepIndex)
        {
            case 1:
            {
                if(formAnalyseRequestKeyword !== "") // check data is correct
                {
                    setFormStepIndex(2);
                }
                else
                {
                    alertAction.current.showAlert("Please complete information"); // show error
                }

                break;
            }

            case 2:
            {
                if(General.isLink(formAnalyseRequestUrl)) // check data is correct
                {
                    setFormStepIndex(3);
                }
                else
                {
                    alertAction.current.showAlert("Please complete information"); // show error
                }

                break;
            }

            case 3:
            {
                if(formAnalyseRequestCountry !== "") // check data is correct
                {
                    setFormStepIndex(4);
                }
                else
                {
                    alertAction.current.showAlert("Please complete information"); // show error
                }

                break;
            }

            case 4:
            {
                if(formAnalyseRequestLanguage !== "") // check data is correct
                {
                    setFormStepIndex(5);
                }
                else
                {
                    alertAction.current.showAlert("Please complete information"); // show error
                }

                break;
            }

            case 5:
            {
                if(formAnalyseRequestCompetitor1 !== "" && formAnalyseRequestCompetitor2 !== "" && formAnalyseRequestCompetitor3 !== "" && formAnalyseRequestCompetitor4 !== "" && formAnalyseRequestCompetitor5 !== "" && formAnalyseRequestCompetitor6 !== "" && formAnalyseRequestCompetitor7 !== "" && formAnalyseRequestCompetitor8 !== "" && formAnalyseRequestCompetitor9 !== "" && formAnalyseRequestCompetitor10 !== "") // check data is correct
                {
                    saveAnalyseRequest();
                }
                else
                {
                    alertAction.current.showAlert("Please complete information"); // show error
                }

                break;
            }
        }
    }

    // send data to server
    const saveAnalyseRequest = () =>
    {
        // if(formAnalyseRequestKeyword !== "" && General.isLink(formAnalyseRequestUrl) && formAnalyseRequestCountry !== "" && formAnalyseRequestLanguage !== "" && formAnalyseRequestCompetitor1 !== "") // check data is correct
        // {
            // setFormLoading(true); // hide btn & show loading
            setSectionActiveIndex(2);

            // send data
            const params =
                {
                    'keyword': formAnalyseRequestKeyword,
                    'url': formAnalyseRequestUrl,
                    'country': formAnalyseRequestCountry,
                    'language': formAnalyseRequestLanguage,
                    'competitor': formAnalyseRequestCompetitor1 + '{#@#}' + formAnalyseRequestCompetitor2 + '{#@#}' + formAnalyseRequestCompetitor3 + '{#@#}' + formAnalyseRequestCompetitor4 + '{#@#}' + formAnalyseRequestCompetitor5 + '{#@#}' + formAnalyseRequestCompetitor6 + '{#@#}' + formAnalyseRequestCompetitor7 + '{#@#}' + formAnalyseRequestCompetitor8 + '{#@#}' + formAnalyseRequestCompetitor9 + '{#@#}' + formAnalyseRequestCompetitor10,
                };
            orderApi.analyseRequest(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Analyse Request Sent", "success"); // show success
                        resetFormAnalyseRequest();

                        setSectionActiveIndex(3);
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error

                        setSectionActiveIndex(1);
                    }

                    // setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    // setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        // }
        // else
        // {
        //     alertAction.current.showAlert("Please complete information"); // show error
        // }
    };

    const resetFormAnalyseRequest = () =>
    {
        setFormAnalyseRequestKeyword("");
        setFormAnalyseRequestUrl("");
        setFormAnalyseRequestCountry("");
        setFormAnalyseRequestLanguage("");
        setFormAnalyseRequestCompetitor1("");
        setFormAnalyseRequestCompetitor2("");
        setFormAnalyseRequestCompetitor3("");
        setFormAnalyseRequestCompetitor4("");
        setFormAnalyseRequestCompetitor5("");
        setFormAnalyseRequestCompetitor6("");
        setFormAnalyseRequestCompetitor7("");
        setFormAnalyseRequestCompetitor8("");
        setFormAnalyseRequestCompetitor9("");
        setFormAnalyseRequestCompetitor10("");
    };

    //
    return (
        <div className="page-content page-analyse-request">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/orders">Orders</Link>
                        <Link className="link" underline="hover" color="inherit" to="/order/new">New Order</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <Container className="page-section-container">
                    <Grid container spacing={4}>
                        <Grid item lg={5} md={12}>
                            <div className="page-section-box">
                                <div className="section-title">Your Complete Analyse Request</div>

                                <div className="content">
                                    {(() =>
                                    {
                                        switch(sectionActiveIndex)
                                        {
                                            case 1:
                                                return (
                                                    <div className="form-box">
                                                        <div className="form-container">
                                                            <Grid container spacing={2} className="form-content">


                                                                {(() =>
                                                                {
                                                                    switch(formStepIndex)
                                                                    {
                                                                        case 1:
                                                                            return (
                                                                                <Grid item xs={12}>
                                                                                    <InputLabel shrink className="form-label" htmlFor="form-analyse-request-keyword">What keyword do you want to get rank for?</InputLabel>
                                                                                    <TextField fullWidth={true} className="form-input" id="form-analyse-request-keyword" placeholder="" value={formAnalyseRequestKeyword} onChange={(e) => setFormAnalyseRequestKeyword(e.target.value)} />
                                                                                </Grid>
                                                                            );

                                                                        case 2:
                                                                            return (
                                                                                <Grid item xs={12}>
                                                                                    <InputLabel shrink className="form-label" htmlFor="form-analyse-request-url">What page (URL) you you want to get rank for?</InputLabel>
                                                                                    <TextField fullWidth={true} className="form-input" id="form-analyse-request-url" placeholder="https:// ..." value={formAnalyseRequestUrl} onChange={(e) => setFormAnalyseRequestUrl(e.target.value)} />
                                                                                </Grid>
                                                                            );

                                                                        case 3:
                                                                            return (
                                                                                <Grid item xs={12}>
                                                                                    <InputLabel shrink className="form-label" htmlFor="form-analyse-request-country">What is your main targeted country?</InputLabel>
                                                                                    {/*<TextField fullWidth={true} className="form-input" id="form-analyse-request-" placeholder="" value={formAnalyseRequestCountry} onChange={(e) => setFormAnalyseRequestCountry(e.target.value)} />*/}
                                                                                    <Select className="form-select" id="form-analyse-request-country" value={formAnalyseRequestCountry} onChange={(e) => setFormAnalyseRequestCountry(e.target.value)}>
                                                                                        {
                                                                                            dataCountries !== null && dataCountries.length > 0 ?
                                                                                                dataCountries.map
                                                                                                (
                                                                                                    (item, index) =>
                                                                                                    {
                                                                                                        return (
                                                                                                            <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                                                                        );
                                                                                                    }
                                                                                                )
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </Select>
                                                                                </Grid>
                                                                            );

                                                                        case 4:
                                                                            return (
                                                                                <Grid item xs={12}>
                                                                                    <InputLabel shrink className="form-label" htmlFor="form-analyse-request-language">What is your main targeted language?</InputLabel>
                                                                                    {/*<TextField fullWidth={true} className="form-input" id="form-analyse-request-" placeholder="" value={formAnalyseRequestLanguage} onChange={(e) => setFormAnalyseRequestLanguage(e.target.value)} />*/}
                                                                                    <Select className="form-select" id="form-analyse-request-language" value={formAnalyseRequestLanguage} onChange={(e) => setFormAnalyseRequestLanguage(e.target.value)}>
                                                                                        {
                                                                                            dataLanguages !== null && dataLanguages.length > 0 ?
                                                                                                dataLanguages.map
                                                                                                (
                                                                                                    (item, index) =>
                                                                                                    {
                                                                                                        return (
                                                                                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                                                                                        );
                                                                                                    }
                                                                                                )
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </Select>
                                                                                </Grid>
                                                                            );

                                                                        case 5:
                                                                            return (
                                                                            <Grid item xs={12}>
                                                                                <InputLabel shrink className="form-label" htmlFor="form-analyse-request-competitor">What are your top 10 competitor's ranked URLs?</InputLabel>
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor1} onChange={(e) => setFormAnalyseRequestCompetitor1(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>1</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor2} onChange={(e) => setFormAnalyseRequestCompetitor2(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>2</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor3} onChange={(e) => setFormAnalyseRequestCompetitor3(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>3</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor4} onChange={(e) => setFormAnalyseRequestCompetitor4(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>4</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor5} onChange={(e) => setFormAnalyseRequestCompetitor5(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>5</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor6} onChange={(e) => setFormAnalyseRequestCompetitor6(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>6</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor7} onChange={(e) => setFormAnalyseRequestCompetitor7(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>7</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor8} onChange={(e) => setFormAnalyseRequestCompetitor8(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>8</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor9} onChange={(e) => setFormAnalyseRequestCompetitor9(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>9</InputAdornment>}} />
                                                                                <TextField className="form-input" placeholder="https:// ..." value={formAnalyseRequestCompetitor10} onChange={(e) => setFormAnalyseRequestCompetitor10(e.target.value)} InputProps={{startAdornment: <InputAdornment position="start" style={{width: '20px'}}>10</InputAdornment>}} />
                                                                            </Grid>
                                                                            );

                                                                        default: return null;
                                                                    }
                                                                })()}

                                                                <Grid item xs={12}>
                                                                    <div className="m-t-16">
                                                                        <Button onClick={checkAnalyseRequest} color="primary" variant="contained">
                                                                            {(() =>
                                                                            {
                                                                                switch(formStepIndex)
                                                                                {
                                                                                    case 5: return "Submit";
                                                                                    default: return "Next";
                                                                                }
                                                                            })()}
                                                                        </Button>
                                                                    </div>
                                                                </Grid>
                                                                {/*{(() =>*/}
                                                                {/*{*/}
                                                                {/*    switch(formStepIndex)*/}
                                                                {/*    {*/}
                                                                {/*        case 5:*/}
                                                                {/*            return (*/}
                                                                {/*                <Grid item xs={12}>*/}
                                                                {/*                    <div className="m-t-16">*/}
                                                                {/*                        <Button onClick={saveAnalyseRequest} color="primary" variant="contained">Submit</Button>*/}
                                                                {/*                    </div>*/}
                                                                {/*                </Grid>*/}
                                                                {/*            );*/}

                                                                {/*        default:*/}
                                                                {/*            return (*/}
                                                                {/*                <Grid item xs={12}>*/}
                                                                {/*                    <div className="m-t-16">*/}
                                                                {/*                        <Button onClick={saveAnalyseRequest} color="primary" variant="contained">Submit</Button>*/}
                                                                {/*                    </div>*/}
                                                                {/*                </Grid>*/}
                                                                {/*            );*/}
                                                                {/*    }*/}
                                                                {/*})()}*/}
                                                            </Grid>
                                                        </div>
                                                    </div>
                                                );
                                            case 2:
                                                return (
                                                    <div className="processing"><img src={imageProcess} /><p>please wait ... <br />Your request is being processed</p></div>
                                                );
                                            case 3:
                                                return (
                                                    <div className="success"><img src={imageSuccessfully} /><p>We will send you the detailed analysis in your email, including the most efficient and customised backlink package that lead you to the expected result.</p></div>
                                                );
                                            default: return null;
                                        }
                                    })()}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                {/* loading */}
                {/*{formLoading ? <LoadingOver /> : null}*/}

                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>
        </div>
    );
}

export default AnalyseRequest;
