import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, Navigate} from "react-router-dom";
import {Button, Container, Grid, TextField} from "@mui/material";
import OrderApi from "../../services/OrderApi";
import Loading from "../../components/Loading/Loading";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import ProductApi from "../../services/ProductApi";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Input from "@mui/material/Input";
import "./GuestPost.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import General from "../../utils/General";

//--------------------------------------------------

function GuestPost(props)
{
    // set page title & page path
    const pageTitle = "Guest Posts";
    usePage().pageData({title: pageTitle, activeMenu: 'order-new'});

    //
    const productType = 2;

    //
    const [productData, setProductData] = useState([]);
    const [productDataLoading, setProductDataLoading] = useState(true);

    const [orderProductData, setOrderProductData] = useState([]);
    const [orderProductLinkData, setOrderProductLinkData] = useState([]);
    const [formOrderLoading, setFormOrderLoading] = useState(false);

    const [csvUpload, setCsvUpload] = useState(false);
    const [orderSubTotal, setOrderSubTotal] = useState("");
    const [orderNumber, setOrderNumber] = useState('');

    let scrollBarRef = useRef();
    const alertAction = useRef(); // init alert

    // load product data
    const loadProductData = () =>
    {
        setProductDataLoading(true); // show loading

        // get data
        ProductApi.find({ type: productType }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setProductData(response.data.products);
                    setOrderProductData(response.data.products);
                }

                setProductDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setProductDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadProductData(); }, []);

    // handle order qty
    const handleIncreaseOrderQTY = (index) =>
    {
        let qty = orderProductData[index].qty > 0 ? parseInt(orderProductData[index].qty) : 0;

        handleOrderQTY(index, qty + 1);
    };

    const handleDecreaseOrderQTY = (index) =>
    {
        let qty = orderProductData[index].qty > 0 ? parseInt(orderProductData[index].qty) : 0;

        handleOrderQTY(index, qty - 1);
    };

    const handleOrderQTY = (index, value) =>
    {
        let tmpData = orderProductData;
        value = value > 50 ? 50 : value;
        value = value < 0 ? 0 : value;
        tmpData[index].qty = value;
        tmpData[index].subTotal = value * tmpData[index].unitCost;

        setOrderProductData([...tmpData, ...[]]);
        if(orderProductLinkData.length > 0) setOrderProductLinkData([]);

        //
        let tempOrderSubTotal = 0;

        for (let i = 0; i < tmpData.length; i++)
        {
            tempOrderSubTotal += tmpData[i].subTotal > 0 ? tmpData[i].subTotal : 0;
        }

        setOrderSubTotal(tempOrderSubTotal);
    };

    //
    const buildPackage = () =>
    {
        let tempOrderProductLinkData = [];

        for (let i = 0; i < orderProductData.length; i++)
        {
            if(orderProductData[i].qty > 0)
            {
                for (let j = 0; j < orderProductData[i].qty; j++)
                {
                    tempOrderProductLinkData[tempOrderProductLinkData.length] = {productId: orderProductData[i].id, title: orderProductData[i].title, url: '', anchorText: '', generalNiche: '', specificNiche: ''}
                }
            }
        }

        if(tempOrderProductLinkData.length === 0)
        {
            alertAction.current.showAlert("Oops! You have zero guest posts in your package. Please select at least one guest post to continue."); // show error
        }

        setOrderProductLinkData([...tempOrderProductLinkData, ...[]]);

        // scroll
        setTimeout
        (
            function(_scrollBarRef)
            {
                const elmTableOrderLink = document.getElementById("table-new-order-link-data");
                _scrollBarRef.scrollTop(elmTableOrderLink.offsetTop - 100);
            },
            150,
            scrollBarRef
        );
    };

    //
    const handleOrderProductLink = (index, type, value) =>
    {
        let tmpData = orderProductLinkData;
        tmpData[index][type] = value;

        if(type === 'url')
        {
            tmpData[index].errorUrl = (value !== '') ? !General.isLink(value) : false;
        }

        setOrderProductLinkData([...tmpData, ...[]]);
    };

    // send data to server
    const orderSave = () =>
    {
        let flagProduct = false;
        let flagLink = false;

        // check order product data
        if(orderProductData !== null && orderProductData.length > 0)
        {
            for (let i = 0; i < orderProductData.length; i++)
            {
                if(orderProductData[i].qty !== undefined && orderProductData[i].qty > 0)
                {
                    flagProduct = true;
                    break;
                }
            }
        }

        // check order product link data
        if(orderProductLinkData !== null && orderProductLinkData.length > 0)
        {
            for (let i = 0; i < orderProductLinkData.length; i++)
            {
                if(General.isLink(orderProductLinkData[i].url) && orderProductLinkData[i].anchorText !== '' && orderProductLinkData[i].generalNiche !== '' && orderProductLinkData[i].specificNiche !== '')
                {
                    flagLink = true;
                }
                else
                {
                    flagLink = false;
                    break;
                }
            }
        }

        if(flagProduct && flagLink) // check data is correct
        {
            setFormOrderLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'products': orderProductData,
                    'links': orderProductLinkData,
                    'productTypeId': productType,
                };
            OrderApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Order Saved", "success"); // show success

                        resetFormOrder();

                        setOrderNumber(response.data.orderNumber);
                    }
                    else
                    {
                        // alertAction.current.showAlert("Incorrect information"); // show error
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormOrderLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormOrderLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetFormOrder = () =>
    {
        if(productData !== null && productData.length > 0)
        {
            for (let i = 0; i < productData.length; i++)
            {
                productData[i].qty = '';
                productData[i].subTotal = '';
            }
        }

        setOrderProductData(productData);
        setOrderProductLinkData([]);
        setOrderSubTotal('');
    };

    // csv
    const handleCsvUpload = (e) =>
    {
        setCsvUpload(e.target.checked);
        setOrderProductLinkData([]);
    };

    const handleCsvDownload = () =>
    {
        let flagProduct = false;
        let csvRecords = "MDA,URL,Anchor,General Niche,Specific Niche\n";

        // check order product data
        if(orderProductData !== null && orderProductData.length > 0)
        {
            for (let i = 0; i < orderProductData.length; i++)
            {
                if(orderProductData[i].qty !== undefined && orderProductData[i].qty > 0)
                {
                    flagProduct = true;

                    for (let j = 0; j < orderProductData[i].qty; j++) csvRecords += orderProductData[i].title + ",,,,\n";
                    // break;
                }
            }
        }

        //
        if(flagProduct)
        {
            let csvContent = "data:text/csv;charset=utf-8," + csvRecords;
            let encodedUri = encodeURI(csvContent);
            let link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "Guest Post.csv");
            document.body.appendChild(link);
            link.click();
        }
        else
        {
            alertAction.current.showAlert("Please Insert Qty"); // show error
        }
    };

    const handleCsvUploadFile = (e) =>
    {
        if(e.target.files !== undefined && e.target.files[0].type === 'text/csv')
        {
            let tempOrderProductLinkData = [];

            for (let i = 0; i < orderProductData.length; i++)
            {
                if(orderProductData[i].qty > 0)
                {
                    for (let j = 0; j < orderProductData[i].qty; j++)
                    {
                        tempOrderProductLinkData[tempOrderProductLinkData.length] = {productId: orderProductData[i].id, title: orderProductData[i].title, url: '', anchorText: '', generalNiche: '', specificNiche: ''}
                    }
                }
            }

            //
            let fileRecords = '';
            let recordItems = '';
            let fileReader = new FileReader();
            fileReader.onload = (e) =>
            {
                fileRecords = fileReader.result.split('\n');

                for(let i = 1; i < fileRecords.length; i++)
                {
                    if(fileRecords[i] !== '')
                    {
                        recordItems = fileRecords[i].split(',');

                        if(recordItems.length > 0)
                        {
                            for(let j = 0; j < tempOrderProductLinkData.length; j++)
                            {
                                if(recordItems[0] === tempOrderProductLinkData[j].title && tempOrderProductLinkData[j].url === '' && tempOrderProductLinkData[j].anchorText === '' && tempOrderProductLinkData[j].generalNiche === '' && tempOrderProductLinkData[j].specificNiche === '')
                                {
                                    tempOrderProductLinkData[j].url = recordItems[1];
                                    tempOrderProductLinkData[j].anchorText = recordItems[2];
                                    tempOrderProductLinkData[j].generalNiche = recordItems[3];
                                    tempOrderProductLinkData[j].specificNiche = recordItems[4];
                                    break;
                                }
                            }
                        }
                    }
                }

                setOrderProductLinkData([...tempOrderProductLinkData, ...[]]);
                setCsvUpload(false);
            };
            fileReader.readAsText(e.target.files[0]);
        }
        else
        {
            alertAction.current.showAlert("The selected file is incorrect"); // show error
        }
    };

    // redirect to payment
    if(orderNumber !== '')
    {
        return <Navigate to={'/order/payment/' + orderNumber} />;
    }

    //
    return (
        <div className="page-content page-quest-post">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/orders">Orders</Link>
                        <Link className="link" underline="hover" color="inherit" to="/order/new">New Order</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar" ref={c => { scrollBarRef = c }}>
                <Container className="page-section-container">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <div className="page-section-box">
                                <div className="section-title">Guest Posts Package Builder</div>

                                <div className="content">
                                    {
                                        productDataLoading ?
                                            null
                                            :
                                            (
                                                orderProductData !== null && orderProductData.length > 0 ?
                                                    <div className="form-box">
                                                        <table className="table-data table-new-order-data">
                                                            <thead>
                                                            <tr>
                                                                <th>Link Type</th>
                                                                <th className="w-160">Unit Cost (USD)</th>
                                                                <th className="w-120 th-qty">Qty</th>
                                                                <th className="w-140">Subtotal</th>
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                            {
                                                                orderProductData.map
                                                                (
                                                                    (item, index) =>
                                                                    {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{item.title}</td>
                                                                                <td>${item.unitCost}</td>
                                                                                <td className="td-qty">
                                                                                    {/*<TextField className=", " type="number" InputProps={{inputProps: {min: 0, max: 50}}} value={item.qty} onChange={(e) => setFormOrderQTY(index, e.target.value)} />*/}
                                                                                    <TextField className="form-input-number" type="text" InputProps={{inputProps: {min: 0, max: 50}}} value={item.qty} onChange={(e) => handleOrderQTY(index, e.target.value.replace(/[^0-9]/g, ''))} />

                                                                                    <div className="btn-increase-decrease-box">
                                                                                        <Button color="success" variant="contained" onClick={() => handleIncreaseOrderQTY(index)}><AddIcon /></Button>
                                                                                        <Button color="error" variant="contained" onClick={() => handleDecreaseOrderQTY(index)}><RemoveIcon /></Button>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{(item.subTotal > 0) ? '$' + item.subTotal : ''}</td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                            </tbody>

                                                            <tfoot >
                                                            <tr className="net-total">
                                                                <td className="td-title" colSpan="3">Net Total</td>
                                                                <td className="td-value">{(orderSubTotal > 0) ? '$' + orderSubTotal : ''}</td>
                                                            </tr>
                                                            </tfoot>
                                                        </table>

                                                        <div className="row-btn-build-package-checkbox-csv">
                                                            <FormControlLabel className="checkbox-box" control={<Checkbox checked={csvUpload} onChange={handleCsvUpload} />} label={<span>Upload a CSV file? <span className="csv-des">(You can download a template if you select this option.)</span></span>} />
                                                            {!csvUpload ? <Button onClick={buildPackage} color="success" variant="contained">{orderProductLinkData.length === 0 ? 'Start Building Your Package' : 'Change Current Package'}</Button> : null}
                                                        </div>
                                                    </div>
                                                    :
                                                    <AlertDataEmpty />
                                            )
                                    }
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div className="page-section-box">
                                <div className="section-title">How To Get Started Using The Package Builder?</div>

                                <div className="content">
                                    <p>Use the plus button to add more of the same type of guest posts and minus button to remove guest posts. Once you are ready with your selection, click on the "Start Building Your Package" button to generate your package template.</p>
                                    <p>You can opt to upload a csv file instead of filling in all the details for larger orders by ticking the "Upload a CSV file option".</p>
                                    <p>Download the template if you chose the CSV option and then populate the file as per instructions and upload to continue.</p>
                                    <p>Otherwise, fill all the fields in the package template. Click on the checkout button to confirm order and move to the payment page where you can choose to either pay now using PayPal or pay later.</p>
                                    <p><strong>The delivery time on our guest posts is usually 14-21 days, although this may be extended if your niche is particularly small or difficult.</strong></p>
                                    <p><strong>Warning: We DO NOT accept Adult, Pharmaceutical or Gambling for our guest posts.</strong></p>
                                </div>
                            </div>
                        </Grid>

                        {/* Csv */}
                        {
                            csvUpload ?
                                <>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div className="page-section-box">
                                            <div className="content m-t-0">
                                                <p>Download the package template and fill in URL, anchor text, general niche and specific niche for each link. For custom packages, you can add additional rows with the MDA to select type of curated link.</p>
                                                <Button color="warning" variant="contained" onClick={handleCsvDownload}>Download Template</Button>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div className="page-section-box">
                                            <div className="content m-t-0">
                                                <p>If your uploaded csv file has special characters such as ™, ©, ® etc. but you do not see them or you see blank anchor text field after uploading, then select another encoding option from the drop down and try again. If none work then please contact support for further guidance.</p>
                                                {/*<Button color="info" variant="contained" onClick={handleCsvUploadFile}>Select and Upload .csv File</Button>*/}

                                                <label htmlFor="icon-button-file">
                                                    <Input accept="text/csv" id="icon-button-file" type="file" className="new-order-input-file-upload" onChange={handleCsvUploadFile} />
                                                    <Button color="info" variant="contained" component="span" className="text-transform-unset">Select and Upload .csv File</Button>
                                                </label>
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                                :
                                null
                        }

                        {/* links */}
                        {
                            (
                                orderProductLinkData !== null && orderProductLinkData.length > 0 ?
                                    <Grid item md={12}>
                                        <div className="form-box">
                                            <table className="table-data table-new-order-link-data" id="table-new-order-link-data">
                                                <thead>
                                                <tr>
                                                    <th className="w-40"></th>
                                                    <th>Link Type</th>
                                                    <th>Url</th>
                                                    <th>Anchor Text</th>
                                                    <th>General Niche</th>
                                                    <th>Specific Niche</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    orderProductLinkData.map
                                                    (
                                                        (item, index) =>
                                                        {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.title}</td>
                                                                    <td><TextField fullWidth={true} className={item.errorUrl ? "form-input error" : "form-input"} value={item.url} onChange={(e) => handleOrderProductLink(index, 'url', e.target.value)} /></td>
                                                                    <td><TextField fullWidth={true} className="form-input" value={item.anchorText} onChange={(e) => handleOrderProductLink(index, 'anchorText', e.target.value)} /></td>
                                                                    <td><TextField fullWidth={true} className="form-input" value={item.generalNiche} onChange={(e) => handleOrderProductLink(index, 'generalNiche', e.target.value)} /></td>
                                                                    <td><TextField fullWidth={true} className="form-input" value={item.specificNiche} onChange={(e) => handleOrderProductLink(index, 'specificNiche', e.target.value)} /></td>
                                                                </tr>
                                                            );
                                                        }
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                    :
                                    null
                            )
                        }

                        {/* btn */}
                        {
                            orderProductLinkData !== null && orderProductLinkData.length > 0 ?
                                <Grid item xs={12}>
                                    <Button onClick={orderSave} color="primary" variant="contained">Check Out</Button>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </Container>


                {/* loading */}
                {formOrderLoading || productDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>
        </div>
    );
}

export default GuestPost;
