import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import AlertAction from "../../components/AlertAction";
import UserApi from "../../services/UserApi";
import { Grid, Container, TextField, Button, InputLabel } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Link } from "react-router-dom";
import imageLogo from '../../images/logo.png';
import "./Login.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import IconButton from '@mui/material/IconButton';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import General from "../../utils/General";

//--------------------------------------------------

function Login(props)
{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");

    const [isLogin, setIsLogin] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [googleReCaptchaLoading, setGoogleReCaptchaLoading] = useState(true);

    const [googleReCaptchaInterval, setGoogleReCaptchaInterval] = useState('');

    const auth = useAuth();
    const alertAction = useRef(); // init alert

    // reCaptcha
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    //
    const generateRecaptcha = async () =>
    {
        if(!executeRecaptcha) return;

        const token = await executeRecaptcha('login');
        setGoogleReCaptchaLoading(false);
        setReCaptchaToken(token);
    };

    useEffect(() => { generateRecaptcha(); let grid = setInterval(generateRecaptcha, 90000); setGoogleReCaptchaInterval(grid); }, [executeRecaptcha]);

    // send login data to server
    const login = () =>
    {
        if(email !== "" && password !== "") // check id & password is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'email': email, 'password': password, 'reCaptchaToken': reCaptchaToken};
            UserApi.login(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        clearInterval(googleReCaptchaInterval);

                        auth.authentication({user: response.data.user, nameFamily: response.data.nameFamily, token: response.data.token});
                        setIsLogin(true);
                    }
                    else
                    {
                        setFormLoading(false); // show btn & hide loading
                        // alertAction.current.showAlert("Incorrect information"); // show error
                        alertAction.current.showAlert(response.message); // show error
                    }

                    generateRecaptcha();
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                    generateRecaptcha();
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // social login
    const loginSocialStart = (type) =>
    {
        let socialUrl = '';

        //
        switch (type)
        {
            case "google":
                socialUrl = 'https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?gsiwebsdk=3&client_id=' + General.sl__Google__ClientId + '&scope=' + General.sl__Google__Scope + '&redirect_uri=' + General.clientUrlLogin + '&prompt=select_account&response_type=token&include_granted_scopes=true&enable_serial_consent=true&flowName=GeneralOAuthFlow';
                break;

            case "facebook":
                socialUrl = 'https://www.facebook.com/v14.0/dialog/oauth?client_id=' + General.sl__Facebook__AppId + '&display=popup&response_type=token&redirect_uri=' + General.clientUrlLogin;
                break;
        }

        let win = window.open(socialUrl, "loginSocial", 'toolbar=0,status=0,width=548,height=600');
        let loginSocialInterval = setInterval
        (
            function()
            {
                try
                {
                    var backUrl = new URL(win.location.href.replace(/#/g, "?").replace("??", "?"));
                    if(backUrl !== "")
                    {
                        var accessToken = backUrl.searchParams.get("access_token");
                        if(accessToken !== "" && accessToken !== null)
                        {
                            win.close();
                            clearInterval(loginSocialInterval);

                            // call login social
                            loginSocial(type, accessToken);
                            // console.log(accessToken)
                        }
                    }
                }
                catch (e)
                {
                }
            },
            1000
        );
    };

    const loginSocial = (type, accessToken) =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        const params = {'social': type, 'accessToken': accessToken, 'reCaptchaToken': reCaptchaToken};
        UserApi.loginSocial(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    clearInterval(googleReCaptchaInterval);

                    auth.authentication({user: response.data.user, nameFamily: response.data.nameFamily, token: response.data.token});
                    setIsLogin(true);
                }
                else
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert(response.message); // show error
                }

                generateRecaptcha();
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
                generateRecaptcha();
            }
        );
    };

    // redirect to panel if user loggedIn
    if(isLogin)
    {
        return <Navigate to="/order/new" />;
    }

    // page content
    return (
        <div className="page-login">
            <Container className="form-box">
                <Grid container justifyContent="center">
                    <Grid item xs={12} className="logo-box"><img src={imageLogo} alt="logo" /></Grid>

                    <Grid item md={8} sm={12} xs={12} className="form-login">
                        <Grid container className="form-container">
                            <Grid item sm={8} xs={12} className="col-left form-content">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className="form-title-box">
                                        <h2 className="form-title">Welcome Back!</h2>
                                        <Link to="/forgot-password" className="link-to-forgot-password">Forgot Password?</Link>
                                    </Grid>

                                    <Grid item xs={12} className="social-login-box social-login-box-1">
                                        <div className="social-login-title-bar">
                                            <span className="title">Login using social networks</span>
                                        </div>

                                        <IconButton className="social-login-btn" onClick={() => loginSocialStart('google')}>
                                            <GoogleIcon className="icon" />
                                        </IconButton>

                                        {/*<IconButton className="social-login-btn" onClick={() => loginSocialStart('facebook')}>*/}
                                        {/*    <FacebookIcon className="icon" />*/}
                                        {/*</IconButton>*/}
                                    </Grid>

                                    <Grid item xs={12} className="social-login-box">
                                        <div className="social-login-title-bar">
                                            <span className="title">OR</span>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-login-email">Email or Username</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-login-email" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-login-password">Password</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-login-password" placeholder="password" type={passwordType} autoComplete="current-password" onChange={(e) => setPassword(e.target.value)} />
                                        {passwordType === 'password' ? <RemoveRedEyeOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('text')} /> : <VisibilityOffOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('password')} />}
                                    </Grid>

                                    <Grid item xs={12} className="m-t-16">
                                        <Button onClick={login} fullWidth={false} color="primary" variant="contained" endIcon={<ArrowForwardIcon />}>Sign In</Button>
                                    </Grid>

                                    {/*<Grid item xs={12} className="login-des">Not registered yet? <Link to="/register" className="link-to-register">Create an Account</Link></Grid>*/}
                                </Grid>
                            </Grid>

                            <Grid item sm={4} xs={12} className="col-right">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className="title">New Here?</div>

                                        <div className="des">Sign up and discover a great amount of new opportunities!</div>

                                        <Link to="/register" className="link-to-register">Sign Up</Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {/* loading */}
            {formLoading || googleReCaptchaLoading ? <LoadingOver /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

// export default Login;
export default Login;
