import React, {useEffect, useRef, useState} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import General from "../../utils/General";
import AlertAction from "../../components/AlertAction";
import "./Activation.css";

//--------------------------------------------------

function Activation(props)
{
    // get page type
    let { messageType } = useParams();

    let isLogin = false;

    const [isRedirect, setIsRedirect] = useState(false);

    const alertAction = useRef(); // init alert

    //
    useEffect
    (
        () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));
            if(authenticate !== undefined && authenticate !== null && authenticate.user != null) isLogin = true;

            if(messageType === 'successfully')
            {
                if(isLogin)
                {
                    authenticate.user.isActive = true;
                    localStorage.setItem(General.localStorageName, JSON.stringify(authenticate));
                }
                alertAction.current.showAlert("Your account has been activated", "success"); // show success
            }
            else
            {
                alertAction.current.showAlert("Your account has not been activated"); // show error
            }

            setIsRedirect(true);
        },
        []
    );

    // redirect
    if(isRedirect)
    {
        return <Navigate to='/login' />;
    }

    //
    return (
        <>

            {/* alert */}
            <AlertAction ref={alertAction} />

        </>
    );
}

export default Activation;
