import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from "react-router-dom";
import {Button, Container, Grid} from "@mui/material";
import "./New.css";
import AnalyseRequest from "./AnalyseRequest";

//--------------------------------------------------

function New(props)
{
    // set page title & page path
    const pageTitle = Menus.orderNew.title;
    usePage().pageData({title: pageTitle, activeMenu: 'order-new'});

    const [sectionActiveIndex, setSectionActiveIndex] = useState(1);

    //
    const handleBtnSectionClick = (index) =>
    {
        setSectionActiveIndex(index);
    };

    //
    return (
        <div className="page-content page-new">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/orders">Orders</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <Container className="page-section-container">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div className="page-section-box">
                                <div className="section-title">Choose One To Begin</div>

                                <div className="content">
                                    <Button className="btn-section" fullWidth={true} color="primary" variant={sectionActiveIndex === 1 ? 'contained' : 'outlined'} onClick={() => handleBtnSectionClick(1)}>Link Insertion</Button>
                                    <Button className="btn-section" fullWidth={true} color="primary" variant={sectionActiveIndex === 2 ? 'contained' : 'outlined'} onClick={() => handleBtnSectionClick(2)}>Guest Posts</Button>
                                </div>
                            </div>

                            <div className="page-section-box transparent">
                                <div className="section-title center no-line p-b-0">Or</div>

                                <div className="content">
                                    <Button component={Link} to="/order/analyse-request" variant="contained" color="warning" fullWidth={true} className="btn-section">Start From Here</Button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <div className="page-section-box">
                                <div className="section-title">
                                    {(() =>
                                    {
                                        switch(sectionActiveIndex)
                                        {
                                            case 1: return <>Link Insertion</>;
                                            case 2: return <>Guest Posts</>;
                                            default: return null;
                                        }
                                    })()}
                                </div>

                                <div className="content">
                                    {(() =>
                                    {
                                        switch(sectionActiveIndex)
                                        {
                                            case 1:
                                                return (
                                                    <div className="section-des">
                                                        <p>Link  insertion is our flagship product. We obtain links that are included inside current material through real outreach. The material already exists and has often done so for many months, if not years, ensuring that you receive a link from a post or page that has aged well and has grown authority in its own right.</p>
                                                        <p><strong>Our two-step strategy for success is as follows:</strong></p>
                                                        <p>Based on the parameters you've selected, we find a suitable page/post for you.</p>
                                                        <p>We make direct contact in order to achieve a link placement inside the material.</p>
                                                        <p>Because they reside on pages that have already gained authority inside Google's index, these links are regarded the holy grail of all links. And, most importantly, every single link we obtain for you comes from a legitimate and actual website.</p>

                                                        <Button component={Link} to="/order/link-insertion" variant="contained" color="error" className="text-transform-unset">Start A New Order</Button>
                                                    </div>
                                                );

                                            case 2:
                                                return (
                                                    <div className="section-des">
                                                        <p>Guest Posts are all about getting your business/website mentions from other websites, while being surrounded by highly relevant content. It is the most natural form of link acquisition. Albeit, this comes at a cost.</p>
                                                        <p><strong>We adopt a four stage process for our guest posts:</strong></p>
                                                        <ol>
                                                            <li>Once you completed your order ( payment) , you can paste your text ( plain text ) into the related box.</li>
                                                            <li>As soon as we have your texts, we study your website and your niche. We use this information to put together a custom-tailored outreach process to help us secure a guest post placement that's highly relevant to your industry.</li>
                                                            <li>We match your content with our database of numbers of  blogs and publishers and  send your text to the webmasters to publish them for you.</li>
                                                            <li>We get back to you with the link where your content has been posted.</li>
                                                        </ol>

                                                        <Button component={Link} to="/order/guest-post" variant="contained" color="error" className="text-transform-unset">Start A New Order</Button>
                                                    </div>
                                                );

                                            default:
                                                return null;
                                        }
                                    })()}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Scrollbars>
        </div>
    );
}

export default New;
