import React, {useState} from 'react';
import Menus from "../../utils/Menu";
import {Link} from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import {Scrollbars} from "react-custom-scrollbars-2";
import imageLogo from "../../images/logo.png";
import "./Menu.css";

//--------------------------------------------------

function Menu(props)
{
    // active link of current page
    const checkPageLink = (currentPage, currentPageRegex = undefined) =>
    {
        if(props.activeMenu === currentPage)
        {
            return "active";
        }

        return null;
    };

    //
    return (
        <div className="col-menu">
            <div className="site-logo"><img src={imageLogo} /></div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="menu-scrollbar">
                <ul className="menu">
                    {
                        Object.keys(Menus).map
                        (
                            (key, i) => (Menus[key].isMenu) ?
                                <li className={checkPageLink(Menus[key].className)} key={i}><Link to={Menus[key].link}>{Menus[key].icon}<span className="title">{Menus[key].title}</span></Link></li>
                                :
                                <li className="menu-section" key={i}>{Menus[key].title}</li>
                        )
                    }
                    {/*<li><Link to="" onClick={props.logOut}><LogoutIcon className="icon" /><span className="title">Logout</span></Link></li>*/}
                </ul>
            </Scrollbars>
        </div>
    );
}

export default Menu;
