import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, Navigate} from "react-router-dom";
import {Button, Container, Grid, TextField} from "@mui/material";
import OrderApi from "../../services/OrderApi";
import Loading from "../../components/Loading/Loading";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import ProductApi from "../../services/ProductApi";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import "./LinkInsertion.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import General from "../../utils/General";

//--------------------------------------------------

function LinkInsertion(props)
{
    // set page title & page path
    const pageTitle = "Moz Link Insertion Packages";
    usePage().pageData({title: pageTitle, activeMenu: 'order-new'});

    //
    const productType = 1;

    //
    const [packageId, setPackageId] = useState('');
    const [packageTitle, setPackageTitle] = useState('');
    const [packageDiscount, setPackageDiscount] = useState('');
    const [packagePrice, setPackagePrice] = useState('');
    const [packageNetTotal, setPackageNetTotal] = useState('');
    const [packageIndex, setPackageIndex] = useState(0);

    const [productData, setProductData] = useState([]);
    const [packageData, setPackageData] = useState([]);
    const [productDataLoading, setProductDataLoading] = useState(true);

    const [orderProductData, setOrderProductData] = useState([]);
    const [orderProductLinkData, setOrderProductLinkData] = useState([]);
    const [formOrderLoading, setFormOrderLoading] = useState(false);

    const [csvUpload, setCsvUpload] = useState(false);
    const [orderSubTotal, setOrderSubTotal] = useState("");
    // const [orderId, setOrderId] = useState(0);
    const [orderNumber, setOrderNumber] = useState('');

    let scrollBarRef = useRef();
    const alertAction = useRef(); // init alert

    // load product data
    const loadProductData = () =>
    {
        setProductDataLoading(true); // show loading

        // get data
        ProductApi.find({ type: productType }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPackageData(response.data.packages);
                    setProductData(response.data.products);

                    handleBtnPackageClick(0, response.data.packages);
                }

                setProductDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setProductDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadProductData(); }, []);

    // handle order qty
    const handleIncreaseOrderQTY = (index) =>
    {
        let qty = orderProductData[index].qty > 0 ? parseInt(orderProductData[index].qty) : 0;

        handleOrderQTY(index, qty + 1);
    };

    const handleDecreaseOrderQTY = (index) =>
    {
        let qty = orderProductData[index].qty > 0 ? parseInt(orderProductData[index].qty) : 0;

        handleOrderQTY(index, qty - 1);
    };

    const handleOrderQTY = (index, value) =>
    {
        let tmpData = orderProductData;
        value = value > 50 ? 50 : value;
        value = value < 0 ? 0 : value;

            tmpData[index].qty = value;
            tmpData[index].subTotal = value * tmpData[index].unitCost;

            setOrderProductData([...tmpData, ...[]]);
            if(orderProductLinkData.length > 0) setOrderProductLinkData([]);

            //
            let tempOrderSubTotal = 0;

            for (let i = 0; i < tmpData.length; i++)
            {
                tempOrderSubTotal += tmpData[i].subTotal > 0 ? tmpData[i].subTotal : 0;
            }

            setOrderSubTotal(tempOrderSubTotal);
    };

    //
    const buildPackage = () =>
    {
        let tempOrderProductLinkData = [];

        for (let i = 0; i < orderProductData.length; i++)
        {
            if(orderProductData[i].qty > 0)
            {
                for (let j = 0; j < orderProductData[i].qty; j++)
                {
                    tempOrderProductLinkData[tempOrderProductLinkData.length] = {productId: orderProductData[i].id, mda: orderProductData[i].mda, url: '', anchorText: '', generalNiche: '', specificNiche: ''}
                }
            }
        }

        if(tempOrderProductLinkData.length === 0)
        {
            alertAction.current.showAlert("Oops! You have no link insertion in your package. Please select at least one curated link to continue."); // show error
        }

        setOrderProductLinkData([...tempOrderProductLinkData, ...[]]);

        // scroll
        setTimeout
        (
            function(_scrollBarRef)
            {
                const elmTableOrderLink = document.getElementById("table-new-order-link-data");
                _scrollBarRef.scrollTop(elmTableOrderLink.offsetTop - 100);
            },
            150,
            scrollBarRef
        );
    };

    //
    const handleOrderProductLink = (index, type, value) =>
    {
        let tmpData = orderProductLinkData;
        tmpData[index][type] = value;

        if(type === 'url')
        {
            tmpData[index].errorUrl = (value !== '') ? !General.isLink(value) : false;
        }

        setOrderProductLinkData([...tmpData, ...[]]);
    };

    // send data to server
    const orderSave = () =>
    {
        let flagProduct = false;
        let flagLink = false;

        // check order product data
        if(orderProductData !== null && orderProductData.length > 0)
        {
            for (let i = 0; i < orderProductData.length; i++)
            {
                if(orderProductData[i].qty !== undefined && orderProductData[i].qty > 0)
                {
                    flagProduct = true;
                    break;
                }
            }
        }

        // check order product link data
        if(orderProductLinkData !== null && orderProductLinkData.length > 0)
        {
            for (let i = 0; i < orderProductLinkData.length; i++)
            {
                if(General.isLink(orderProductLinkData[i].url) && orderProductLinkData[i].anchorText !== '' && orderProductLinkData[i].generalNiche !== '' && orderProductLinkData[i].specificNiche !== '')
                {
                    flagLink = true;
                }
                else
                {
                    flagLink = false;
                    break;
                }
            }
        }

        if(flagProduct && flagLink) // check data is correct
        {
            setFormOrderLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'packageId': packageId,
                    'products': orderProductData,
                    'links': orderProductLinkData,
                    'productTypeId': productType,
                };
            OrderApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Order Saved", "success"); // show success

                        resetFormOrder();
                        handleBtnPackageClick(0);

                        // setOrderId(response.data.orderId);
                        setOrderNumber(response.data.orderNumber);
                    }
                    else
                    {
                        // alertAction.current.showAlert("Incorrect information"); // show error
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormOrderLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormOrderLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetFormOrder = () =>
    {
        if(productData !== null && productData.length > 0)
        {
            for (let i = 0; i < productData.length; i++)
            {
                productData[i].qty = '';
                productData[i].subTotal = '';
            }
        }

        setOrderProductData(productData);
        setOrderProductLinkData([]);
        setOrderSubTotal('');
    };

    //
    const handleBtnPackageClick = (index, packageDataTmp = null) =>
    {
        let pd = packageDataTmp !== null ? packageDataTmp : packageData;
        let tmpData = (pd !== null && pd[index] !== undefined) ? pd[index].products : productData;
        let tempOrderSubTotal = 0;

        setPackageIndex(index);
        setPackageId((pd !== null && pd[index] !== undefined) ? pd[index].id : 0);
        setPackageDiscount((pd !== null && pd[index] !== undefined) ? pd[index].discount : '');
        setPackageNetTotal((pd !== null && pd[index] !== undefined) ? pd[index].netTotal : '');
        setPackagePrice((pd !== null && pd[index] !== undefined) ? pd[index].packagePrice : '');
        setPackageTitle((pd !== null && pd[index] !== undefined) ? pd[index].title : 'Make Your Own');

        setOrderProductData([...tmpData, ...[]]);
        setOrderProductLinkData([]);
        setCsvUpload(false);

        if(index < pd.length)
        {
            for (let i = 0; i < tmpData.length; i++)
            {
                tempOrderSubTotal += tmpData[i].subTotal > 0 ? tmpData[i].subTotal : 0;
            }
        }
        else
        {
            resetFormOrder();
        }

        setOrderSubTotal(tempOrderSubTotal);
    };

    // csv
    const handleCsvUpload = (e) =>
    {
        setCsvUpload(e.target.checked);
        setOrderProductLinkData([]);
    };

    const handleCsvDownload = () =>
    {
        let flagProduct = false;
        let csvRecords = "MDA,URL,Anchor,General Niche,Specific Niche\n";

        // check order product data
        if(orderProductData !== null && orderProductData.length > 0)
        {
            for (let i = 0; i < orderProductData.length; i++)
            {
                if(orderProductData[i].qty !== undefined && orderProductData[i].qty > 0)
                {
                    flagProduct = true;

                    for (let j = 0; j < orderProductData[i].qty; j++) csvRecords += orderProductData[i].mda + ",,,,\n";
                    // break;
                }
            }
        }

        //
        if(flagProduct)
        {
            let csvContent = "data:text/csv;charset=utf-8," + csvRecords;
            let encodedUri = encodeURI(csvContent);
            let link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "Curated Link " + packageTitle + ".csv");
            document.body.appendChild(link);
            link.click();
        }
        else
        {
            alertAction.current.showAlert("Please Insert Qty"); // show error
        }
    };

    const handleCsvUploadFile = (e) =>
    {
        if(e.target.files !== undefined && e.target.files[0].type === 'text/csv')
        {
            let tempOrderProductLinkData = [];

            for (let i = 0; i < orderProductData.length; i++)
            {
                if(orderProductData[i].qty > 0)
                {
                    for (let j = 0; j < orderProductData[i].qty; j++)
                    {
                        tempOrderProductLinkData[tempOrderProductLinkData.length] = {productId: orderProductData[i].id, mda: orderProductData[i].mda, url: '', anchorText: '', generalNiche: '', specificNiche: ''}
                    }
                }
            }

            //
            let fileRecords = '';
            let recordItems = '';
            let fileReader = new FileReader();
            fileReader.onload = (e) =>
            {
                fileRecords = fileReader.result.split('\n');

                for(let i = 1; i < fileRecords.length; i++)
                {
                    if(fileRecords[i] !== '')
                    {
                        recordItems = fileRecords[i].split(',');

                        if(recordItems.length > 0)
                        {
                            for(let j = 0; j < tempOrderProductLinkData.length; j++)
                            {
                                if(recordItems[0] === tempOrderProductLinkData[j].mda && tempOrderProductLinkData[j].url === '' && tempOrderProductLinkData[j].anchorText === '' && tempOrderProductLinkData[j].generalNiche === '' && tempOrderProductLinkData[j].specificNiche === '')
                                {
                                    tempOrderProductLinkData[j].url = recordItems[1];
                                    tempOrderProductLinkData[j].anchorText = recordItems[2];
                                    tempOrderProductLinkData[j].generalNiche = recordItems[3];
                                    tempOrderProductLinkData[j].specificNiche = recordItems[4];
                                    break;
                                }
                            }
                        }
                    }
                }

                setOrderProductLinkData([...tempOrderProductLinkData, ...[]]);
                setCsvUpload(false);
            };
            fileReader.readAsText(e.target.files[0]);
        }
        else
        {
            alertAction.current.showAlert("The selected file is incorrect"); // show error
        }
    };

    // redirect to payment
    if(orderNumber !== '')
    {
        return <Navigate to={'/order/payment/' + orderNumber} />;
    }

    //
    return (
        <div className="page-content page-link-insertion">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/orders">Orders</Link>
                        <Link className="link" underline="hover" color="inherit" to="/order/new">New Order</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar" ref={c => { scrollBarRef = c }}>
                <Container className="page-section-container">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div className="page-section-box">
                                <div className="section-title">Choose One To Begin</div>

                                <div className="content">
                                    {
                                        productDataLoading ?
                                            null
                                            :
                                            <>
                                                {
                                                    packageData !== null && packageData.length > 0 ?
                                                        packageData.map
                                                        (
                                                            (item, index) =>
                                                            {
                                                                return (
                                                                    <Button key={index} className="btn-section" fullWidth={true} variant={packageIndex === index ? 'contained' : 'outlined'} onClick={() => handleBtnPackageClick(index)}>{item.title} (${item.packagePrice})</Button>

                                                                );
                                                            }
                                                        )
                                                        :
                                                        null
                                                }
                                                <Button className="btn-section" fullWidth={true} variant={packageIndex === packageData.length ? 'contained' : 'outlined'} onClick={() => handleBtnPackageClick(packageData.length)}>Make Your Own Package</Button>
                                            </>
                                    }
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <div className="page-section-box">
                                <div className="section-title">{packageTitle}{packagePrice > 0 ? ' ($' + packagePrice + ')' : ''}</div>

                                <div className="content">
                                    {
                                        productDataLoading ?
                                            null
                                            :
                                            (
                                                orderProductData !== null && orderProductData.length > 0 ?
                                                    <div className="form-box">
                                                        <table className="table-data table-new-order-data">
                                                            <thead>
                                                            <tr>
                                                                <th>Grade</th>
                                                                <th>Moz DA</th>
                                                                <th># MDA</th>
                                                                <th>Unit Cost (USD)</th>
                                                                <th>Qty</th>
                                                                <th>Subtotal</th>
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                            {
                                                                orderProductData.map
                                                                (
                                                                    (item, index) =>
                                                                    {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{item.title}</td>
                                                                                <td>{item.mozDA}</td>
                                                                                <td>{item.mda}</td>
                                                                                <td>${item.unitCost}</td>
                                                                                <td className="td-qty">
                                                                                    {
                                                                                        packageIndex === packageData.length ?
                                                                                            <>
                                                                                                <TextField className="form-input-number" type="text" InputProps={{inputProps: {min: 0, max: 50}}} value={item.qty} onChange={(e) => handleOrderQTY(index, e.target.value.replace(/[^0-9]/g, ''))} />

                                                                                                <div className="btn-increase-decrease-box">
                                                                                                    <Button color="success" variant="contained" onClick={() => handleIncreaseOrderQTY(index)}><AddIcon /></Button>
                                                                                                    <Button color="error" variant="contained" onClick={() => handleDecreaseOrderQTY(index)}><RemoveIcon /></Button>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            item.qty
                                                                                    }
                                                                                </td>
                                                                                <td>{(item.subTotal > 0) ? '$' + item.subTotal : ''}</td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                            </tbody>

                                                            <tfoot >
                                                            <tr className="net-total">
                                                                <td className="td-title" colSpan="5">Net Total{packageIndex === packageData.length ? '' : <span className="des"> (What you would normally pay)</span>}</td>
                                                                <td>{(orderSubTotal > 0) ? '$' + orderSubTotal : ''}</td>
                                                            </tr>

                                                            {
                                                                packageIndex === packageData.length ?
                                                                    null
                                                                    :
                                                                    <tr className="package-discount">
                                                                        <td className="td-title" colSpan="5">You Save</td>
                                                                        <td>{(packageDiscount > 0) ? '$' + packageDiscount : ''}</td>
                                                                    </tr>
                                                            }

                                                            {
                                                                packageIndex === packageData.length ?
                                                                    null
                                                                    :
                                                                    <tr className="package-price">
                                                                        <td className="td-title" colSpan="5">{packageTitle} Price</td>
                                                                        <td>{(packagePrice > 0) ? '$' + packagePrice : ''}</td>
                                                                    </tr>
                                                            }
                                                            </tfoot>
                                                        </table>

                                                        <div className="row-btn-build-package-checkbox-csv">
                                                            <FormControlLabel className="checkbox-box" control={<Checkbox defaultChecked checked={csvUpload} onChange={handleCsvUpload} />} label={<span>Upload a CSV file? <span className="csv-des">(You can download a template if you select this option.)</span></span>} />
                                                            {!csvUpload ? <Button onClick={buildPackage} color="success" variant="contained">{orderProductLinkData.length === 0 ? 'Start Building Your Package' : 'Change Current Package'}</Button> : null}
                                                        </div>
                                                    </div>
                                                    :
                                                    <AlertDataEmpty />
                                            )
                                    }
                                </div>
                            </div>
                        </Grid>

                        {/* Csv */}
                        {
                            csvUpload ?
                                <>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div className="page-section-box">
                                            <div className="content m-t-0">
                                                <p>Download the package template and fill in URL, anchor text, general niche and specific niche for each link. For custom packages, you can add additional rows with the MDA to select type of curated link.</p>
                                                <Button color="warning" variant="contained" onClick={handleCsvDownload}>Download Template</Button>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div className="page-section-box">
                                            <div className="content m-t-0">
                                                <p>If your uploaded csv file has special characters such as ™, ©, ® etc. but you do not see them or you see blank anchor text field after uploading, then select another encoding option from the drop down and try again. If none work then please contact support for further guidance.</p>
                                                {/*<Button color="info" variant="contained" onClick={handleCsvUploadFile}>Select and Upload .csv File</Button>*/}

                                                <label htmlFor="icon-button-file">
                                                    <Input accept="text/csv" id="icon-button-file" type="file" className="new-order-input-file-upload" onChange={handleCsvUploadFile} />
                                                    <Button color="info" variant="contained" component="span" className="text-transform-unset">Select and Upload .csv File</Button>
                                                </label>
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                                :
                                null
                        }

                        {/* links */}
                        {
                            orderProductLinkData !== null && orderProductLinkData.length > 0 ?
                                <Grid item md={12}>
                                    <div className="form-box">
                                        <table className="table-data table-new-order-link-data" id="table-new-order-link-data">
                                            <thead>
                                            <tr>
                                                <th className="w-40"></th>
                                                <th>Link Type</th>
                                                <th>Url</th>
                                                <th>Anchor Text</th>
                                                <th>General Niche</th>
                                                <th>Specific Niche</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                orderProductLinkData.map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.mda}</td>
                                                                <td><TextField fullWidth={true} className={item.errorUrl ? "form-input error" : "form-input"} value={item.url} onChange={(e) => handleOrderProductLink(index, 'url', e.target.value)} /></td>
                                                                <td><TextField fullWidth={true} className="form-input" value={item.anchorText} onChange={(e) => handleOrderProductLink(index, 'anchorText', e.target.value)} /></td>
                                                                <td><TextField fullWidth={true} className="form-input" value={item.generalNiche} onChange={(e) => handleOrderProductLink(index, 'generalNiche', e.target.value)} /></td>
                                                                <td><TextField fullWidth={true} className="form-input" value={item.specificNiche} onChange={(e) => handleOrderProductLink(index, 'specificNiche', e.target.value)} /></td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </Grid>
                                :
                                null
                        }

                        {/* btn */}
                        {
                            orderProductLinkData !== null && orderProductLinkData.length > 0 ?
                                <Grid item xs={12}>
                                    <Button onClick={orderSave} color="primary" variant="contained">Check Out</Button>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </Container>


                {/* loading */}
                {formOrderLoading || productDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>
        </div>
    );
}

export default LinkInsertion;
