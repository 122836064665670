import { createContext, useContext } from 'react';
import General from "../utils/General";

//--------------------------------------------------

// const localStorageName = "authenticate";

export const AuthContext = createContext
(
    {
        authenticated: false,
        token: () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));

            General.authorizationToken = (authenticate !== undefined && authenticate !== null) ? {'Authorization': 'Bearer ' + authenticate.token} : "";
            General.authorizationNameFamily = (authenticate !== undefined && authenticate !== null) ? authenticate.nameFamily : "";
            General.user = (authenticate !== undefined && authenticate !== null) ? authenticate.user : null;
        },
        authentication: () => {},
        checkAuthenticate: () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));
            let authenticated = false;

            // check id & token exist then check token is valid
            if (authenticate !== undefined && authenticate !== null)
            {
                authenticated = true;
            }

            return authenticated;
        },
        getNameFamily: () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));

            return (authenticate !== undefined && authenticate !== null) ? authenticate.nameFamily : "";
        },
        getUser: () =>
        {
            let authenticate = JSON.parse(localStorage.getItem(General.localStorageName));

            return (authenticate !== undefined && authenticate !== null) ? authenticate.user : null;
        },
        getAuthenticate: () =>
        {
            return localStorage.getItem(General.localStorageName);
        },
        setAuthenticate: (data) =>
        {
            localStorage.setItem(General.localStorageName, JSON.stringify(data));
        }
    }
);

export function useAuth()
{
    return useContext(AuthContext);
}
