import React, {useCallback, useEffect, useRef, useState} from 'react';
import AlertAction from "../../components/AlertAction";
import UserApi from "../../services/UserApi";
import { Grid, Container, TextField, Button, InputLabel } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Link } from "react-router-dom";
import imageLogo from '../../images/logo.png';
import LockIcon from '@mui/icons-material/Lock';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import CloseIcon from '@mui/icons-material/Close';
import CountryApi from "../../services/CountryApi";
import BusinessApi from "../../services/BusinessApi";
import MonthlySpendApi from "../../services/MonthlySpendApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Register.css";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import General from "../../utils/General";

//--------------------------------------------------

function Register()
{
    // get referral code
    const query = new URLSearchParams(window.location.search);

    //
    const [formRegisterEmail, setFormRegisterEmail] = useState("");
    const [formRegisterFirstName, setFormRegisterFirstName] = useState("");
    const [formRegisterLastName, setFormRegisterLastName] = useState("");
    const [formRegisterBusiness, setFormRegisterBusiness] = useState("0");
    const [formRegisterMonthlySpend, setFormRegisterMonthlySpend] = useState("0");
    const [formRegisterCountry, setFormRegisterCountry] = useState("0");
    const [formRegisterReferralCode, setFormRegisterReferralCode] = useState(query !== undefined && query.get('ref-code') !== undefined && query.get('ref-code') !== null && query.get('ref-code') !== '' ? query.get('ref-code') : "");
    const [formRegisterPassword, setFormRegisterPassword] = useState("");
    const [formRegisterPasswordRep, setFormRegisterPasswordRep] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordRepType, setPasswordRepType] = useState("password");
    const [isStepOne, setIsStepOne] = useState(true);
    const [hasReferralCode, setHasReferralCode] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [termsConditionDialogOpen, setTermsConditionDialogOpen] = useState(false);

    const [dataCountries, setDataCountries] = useState([]);
    const [dataBusinesses, setDataBusinesses] = useState([]);
    const [dataMonthlySpends, setDataMonthlySpends] = useState([]);

    const [formLoading, setFormLoading] = useState(false);
    const [googleReCaptchaLoading, setGoogleReCaptchaLoading] = useState(true);

    const [googleReCaptchaInterval, setGoogleReCaptchaInterval] = useState('');

    const alertAction = useRef(); // init alert

    // reCaptcha
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    // const reloadRecaptcha = useCallback(async () => { generateRecaptcha(); }, [executeRecaptcha]);
    const generateRecaptcha = async () =>
    {
        if(!executeRecaptcha) return;

        const token = await executeRecaptcha('login');
        // console.log(token);
        setGoogleReCaptchaLoading(false);
        setReCaptchaToken(token);
    };

    useEffect(() => { generateRecaptcha(); let grid = setInterval(generateRecaptcha, 90000); setGoogleReCaptchaInterval(grid); }, [executeRecaptcha]);

    // load page data
    const loadData = () =>
    {
        // get data => Country
        CountryApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataCountries(response.data.list);
                }
            }
        );

        // get data => Businesses
        BusinessApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataBusinesses(response.data.list);
                }
            }
        );

        // get data => MonthlySpends
        MonthlySpendApi.find().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setDataMonthlySpends(response.data.list);
                }
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // send register data to server
    const register = () =>
    {
        if(formRegisterEmail !== "" && General.isEmail(formRegisterEmail) && formRegisterFirstName !== "" && formRegisterLastName !== "" && formRegisterCountry !== "0" && acceptTerms && formRegisterPassword !== "" && formRegisterPasswordRep !== "" && formRegisterPassword === formRegisterPasswordRep) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'email': formRegisterEmail,
                    'firstName': formRegisterFirstName,
                    'lastName': formRegisterLastName,
                    'business': formRegisterBusiness,
                    'monthlySpend': formRegisterMonthlySpend,
                    'country': formRegisterCountry,
                    'referralCode': hasReferralCode ? formRegisterReferralCode : '',
                    'password': formRegisterPassword,
                    'reCaptchaToken': reCaptchaToken
                };
            UserApi.register(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        clearInterval(googleReCaptchaInterval);

                        alertAction.current.showAlert("Resister successfully", "success"); // show success

                        resetFormResister();
                    }
                    else
                    {
                        // alertAction.current.showAlert("Incorrect information"); // show error
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading

                    generateRecaptcha();
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error

                    generateRecaptcha();
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    const resetFormResister = () =>
    {
        setFormRegisterEmail("");
        setFormRegisterFirstName("");
        setFormRegisterLastName("");
        setFormRegisterBusiness("0");
        setFormRegisterMonthlySpend("0");
        setFormRegisterCountry("0");
        setFormRegisterReferralCode("");
        setFormRegisterPassword("");
        setFormRegisterPasswordRep("");
        setPasswordType("password");
        setPasswordRepType("password");
        setIsStepOne(true);
        setHasReferralCode(false);
        setAcceptTerms(false);
    };

    // next & pre step
    const nextStep = () =>
    {
        if(formRegisterEmail !== "" && General.isEmail(formRegisterEmail) && formRegisterFirstName !== "" && formRegisterLastName !== "") // check data is correct
            setIsStepOne(false);
        else
            alertAction.current.showAlert("Please complete information"); // show error
    };

    const preStep = () =>
    {
        setIsStepOne(true);
    };

    // dialog terms
    const handleTermsConditionDialog = () =>
    {
        setTermsConditionDialogOpen(!termsConditionDialogOpen);
    };

    const handleTermsConditionAccept = () =>
    {
        setAcceptTerms(true);
        setTermsConditionDialogOpen(false);
    };

    // page content
    return (
        <div className="page-register">
            <Container className="form-box">
                <Grid container justifyContent="center" className="form-container">
                    <Grid item xs={12} className="logo-box"><img src={imageLogo} alt="logo" /></Grid>

                    <Grid item md={6} sm={8} xs={12} className="form-register">
                        <Grid container className="form-container">
                            <Grid item sm={12} xs={12} className="form-content">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className="form-title-box">
                                        <h2 className="form-title">CREATE AN ACCOUNT</h2>
                                        {
                                            isStepOne ?
                                                <div className="link-to-login-box">
                                                    <span>or</span>
                                                    <Link to="/login" className="link-to-login"><LockIcon className="icon" /> Login</Link>
                                                </div>
                                                :
                                                <Button onClick={preStep} className="form-btn-previous" variant="contained" startIcon={<KeyboardDoubleArrowLeftIcon className="icon" />}>previous</Button>
                                        }
                                    </Grid>
                                </Grid>

                                {
                                    isStepOne ?
                                        <Grid container spacing={2}>
                                            <Grid item sm={12} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-email">Email</InputLabel>
                                                <TextField fullWidth={true} className="form-input" id="form-register-email" placeholder="Enter Email address" value={formRegisterEmail} onChange={(e) => setFormRegisterEmail(e.target.value)} />
                                            </Grid>

                                            <Grid item sm={6} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-first-name">First Name</InputLabel>
                                                <TextField fullWidth={true} className="form-input" id="form-register-first-name" placeholder="Enter First Name" value={formRegisterFirstName} onChange={(e) => setFormRegisterFirstName(e.target.value)} />
                                            </Grid>

                                            <Grid item sm={6} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-last-name">Last Name</InputLabel>
                                                <TextField fullWidth={true} className="form-input" id="form-register-last-name" placeholder="Enter Last Name" value={formRegisterLastName} onChange={(e) => setFormRegisterLastName(e.target.value)} />
                                            </Grid>

                                            <Grid item sm={12} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-business">Describe Your Business <span className="optional">(Optional)</span></InputLabel>
                                                <Select className="form-select" id="form-register-business" value={formRegisterBusiness} onChange={(e) => setFormRegisterBusiness(e.target.value)}>
                                                    <MenuItem value="0">I am a...</MenuItem>
                                                    {
                                                        dataBusinesses !== null && dataBusinesses.length > 0 ?
                                                            dataBusinesses.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Select>
                                            </Grid>

                                            <Grid item sm={12} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-monthly-spend">Estimated Monthly Spend <span className="optional">(Optional)</span></InputLabel>
                                                <Select className="form-select" id="form-register-monthly-spend" value={formRegisterMonthlySpend} onChange={(e) => setFormRegisterMonthlySpend(e.target.value)}>
                                                    <MenuItem value="0">I spend...</MenuItem>
                                                    {
                                                        dataMonthlySpends !== null && dataMonthlySpends.length > 0 ?
                                                            dataMonthlySpends.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Select>
                                            </Grid>

                                            <Grid item xs={12} className="m-t-16">
                                                <Button onClick={nextStep} fullWidth={true} color="primary" variant="contained" endIcon={<ArrowForwardIcon />}>Next</Button>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container spacing={2}>
                                            <Grid item sm={12} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-email-2">Email</InputLabel>
                                                <TextField fullWidth={true} className="form-input" id="form-register-email-2" disabled={true} value={formRegisterEmail} />
                                            </Grid>

                                            <Grid item sm={12} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-country">Country Of Residence</InputLabel>
                                                <Select className="form-select" id="form-register-country" value={formRegisterCountry} onChange={(e) => setFormRegisterCountry(e.target.value)}>
                                                    <MenuItem value="0">Your Country...</MenuItem>
                                                    {
                                                        dataCountries !== null && dataCountries.length > 0 ?
                                                            dataCountries.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }
                                                </Select>
                                            </Grid>

                                            <Grid item sm={6} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-password">Password</InputLabel>
                                                <TextField fullWidth={true} className="form-input" id="form-register-password" placeholder="******" type={passwordType} autoComplete="current-password" value={formRegisterPassword} onChange={(e) => setFormRegisterPassword(e.target.value)} />
                                                {passwordType === 'password' ? <RemoveRedEyeOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('text')} /> : <VisibilityOffOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordType('password')} />}
                                            </Grid>

                                            <Grid item sm={6} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-password-rep">Confirm Password</InputLabel>
                                                <TextField fullWidth={true} className="form-input" id="form-register-password-rep" placeholder="******" type={passwordRepType} autoComplete="current-password" value={formRegisterPasswordRep} onChange={(e) => setFormRegisterPasswordRep(e.target.value)} />
                                                {passwordRepType === 'password' ? <RemoveRedEyeOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordRepType('text')} /> : <VisibilityOffOutlinedIcon className="form-btn-password-show-hide" onClick={() => setPasswordRepType('password')} />}
                                            </Grid>

                                            <Grid item sm={12} xs={12}>
                                                <InputLabel shrink className="form-label" htmlFor="form-register-referral-code">Referral Code <span className="optional">(Optional)</span></InputLabel>
                                                <TextField fullWidth={true} className="form-input" id="form-register-referral-code" placeholder="Referral code as rraf981" value={formRegisterReferralCode} onChange={(e) => setFormRegisterReferralCode(e.target.value)} />

                                                {/*<FormControlLabel control={<Checkbox onChange={(e) => setHasReferralCode(e.target.checked)} checked={hasReferralCode} />} label={<span className="form-label">I have a referral ID that I wish to enter. <span className="optional">(Optional)</span></span>} />*/}
                                                {/*{*/}
                                                {/*    hasReferralCode ?*/}
                                                {/*        <TextField fullWidth={true} className="form-input" id="form-register-referral-code" placeholder="Referral code as rraf981" value={formRegisterReferralCode} onChange={(e) => setFormRegisterReferralCode(e.target.value)} />*/}
                                                {/*        :*/}
                                                {/*        null*/}
                                                {/*}*/}
                                            </Grid>

                                            <Grid item sm={12} xs={12}>
                                                <FormControlLabel control={<Checkbox onChange={(e) => setAcceptTerms(e.target.checked)} checked={acceptTerms} />} label={<span className="form-label">Yes, I have read the <a href="https://eiredigital.com/terms-conditions/" target="_blank" className="form-btn-terms">terms and conditions</a>{/*<Button onClick={handleTermsConditionDialog} className="form-btn-terms">terms and conditions.</Button>*/}</span>} />
                                            </Grid>

                                            <Grid item xs={12} className="m-t-16">
                                                <Button onClick={register} fullWidth={true} color="primary" variant="contained">Register</Button>
                                            </Grid>
                                        </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {/* terms and condition */}
            {/*
            <Dialog open={termsConditionDialogOpen} onClose={handleTermsConditionDialog} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <div className="dialog-title">
                        <span className="title">Terms and Conditions</span>
                        <IconButton className="dialog-close" onClick={handleTermsConditionDialog}><CloseIcon className="icon" /></IconButton>
                    </div>
                </DialogTitle>

                <DialogContent className="dialog-content dialog-content-des">
                    <p className="title">In General</p>
                    <p>This document governs your relationship with Rhino Rank ("Website"). Access to and use of this Website and the products and services available through this Website (collectively, the "Services") are subject to the following terms, conditions and notices (the "Terms of Service"). By using the Services, you are agreeing to all of the Terms of Service, as may be updated by us from time to time. You should check this page regularly to take notice of any changes we may have made to the Terms of Service.</p>
                    <p>Access to this Website is permitted on a temporary basis, and we reserve the right to withdraw or amend the Services without notice. We will not be liable if for any reason this Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts or all of this Website. This Website may contain links to other websites, which are not operated by Rhino Rank. Your use of the Linked Sites will be subject to the terms of use and service contained within each such site. This Website is handled and operated by Rhino Rank.</p>

                    <p className="title">Prohibitions</p>
                    <p>You must not misuse this Website. You will not: commit or encourage a criminal offense; transmit or distribute a virus, trojan, worm, logic bomb or any other material which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene; hack into any aspect of the Service; corrupt data; cause annoyance to other users; infringe upon the rights of any other person's proprietary rights; send any unsolicited advertising or promotional material, commonly referred to as "spam"; or attempt to affect the performance or functionality of any computer facilities of or accessed through this Website. Breaching this provision would constitute a criminal offense and Rhino Rank will report any such breach to the relevant law enforcement authorities and disclose your identity to them.</p>
                    <p>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of this Website or to your downloading of any material posted on it, or on any website linked to it.</p>

                    <p className="title">Intellectual Property, Software and Content</p>
                    <p>The intellectual property rights in all software and content (including photographic images) made available to you on or through this Website remains the property of Rhino Rank or its licensors and are protected by copyright laws and treaties around the world. All such rights are reserved Rhino Rank and its licensors. You may store, print and display the content supplied solely for your own personal use. You are not permitted to publish, manipulate, distribute or otherwise reproduce, in any format, any of the content or copies of the content supplied to you or which appears on this Website nor may you use any such content in connection with any business or commercial enterprise.</p>

                    <p className="title">Terms of Sale</p>
                    <p>By placing an order you are offering to purchase a product on and subject to the following terms and conditions. In order to contract with Rhino Rank you must be over 18 years of age and possess a valid PayPal account. Rhino Rank retains the right to refuse any request made by you. If your order is accepted we will change your order status to “In Progress”. When placing an order you undertake that all details you provide to us are true and accurate, that you are an authorized user of the PayPal account used to place your order and that there are sufficient funds to cover the cost of the goods. The cost of foreign products and services may fluctuate. All prices advertised are subject to such changes.</p>
                    <p>It is important to note that all sales are FINAL. We usually start working on an order within 2-hours. Once your order status changes to ‘In Progress’, then we will no longer be able to issue any refund(s).</p>

                    <p className="title">For the sake of transparency with our Link Insertion, it is important to note that we do not guarantee:</p>
                    <p>
                        <ul>
                            <li>Complete Contextual Relevancy.</li>
                            <li>Alexa Rankings.</li>
                            <li>SEMRUSH Traffic.</li>
                            <li>DA.</li>
                        </ul>
                    </p>
                    <p>The only thing we guarantee is Linking Root Domains (as taken from majestic.com). We provide DA as an average – but we do not guarantee DA.</p>

                    <p className="title">Third-Party Vendors</p>
                    <p>It is important to note that Rhino Rank utilises third party vendors to help deliver our service. As such, we will use the information you provide (such as URL, Anchor Text and Niche), and provide these to our third party vendors.</p>
                    <p>Our third party vendor help us deliver our service through undergoing processes such as manual outreach, and securing link placements. Regardless, we still guarantee the metrics we state (Root Domains), and work to the highest standard possible.</p>
                    <p>Should our third party vendors ever underdeliver on the metrics we provide to you, then we will rectify the situation with either replacement links or a partial/full refund.</p>

                    <p className="title">Refunds & Charge-backs</p>
                    <p>Online payments made through Rhino Rank are handled by PayPal. Payments made through PayPal will appear on your statement as ‘paypal’. For refunds, charge-backs or disputes for online paid orders, please contact us on the details mentioned below.</p>
                    <p>We will only issue a refund in the case that we have underdelivered on what we promise.</p>

                    <p className="title">(a) Our Contract</p>
                    <p>When you place an order, you will receive an acknowledgement e-mail and or webpage confirming receipt of your order: this email and or webpage will only be an acknowledgement and will not constitute acceptance of your order. A contract between us will not be formed until we update your order with the “In Progress” status.</p>

                    <p className="title">(b) Pricing and Availability</p>
                    <p>Whilst we try and ensure that all details, descriptions and prices which appear on this Website are accurate, errors may occur. If we discover an error in the price of any goods which you have ordered we will inform you of this as soon as possible and give you the option of reconfirming your order at the correct price or cancelling it. If we are unable to contact you we will treat the order as cancelled. If you cancel and you have already paid for the goods, you will receive a full refund from Rhino Rank.</p>

                    <p className="title">Customer Support</p>
                    <p>Our Customer Support team will attempt to assist you where possible regarding your order. You can contact the Customer Support team by clicking “Contact Us” on our website and submitting your request via the provided Contact Form, or selecting “Live Chat” where available on our mobile application or website.</p>
                    <p>For more information regarding the approximate delivery time of your order, or should you encounter an issue with the order delivered, please contact our Customer Support team by clicking “Contact Us” on our website and submitting your request via the provided Contact Form, or selecting “Live Chat” where available on our mobile application or website (Live Chat is only avaliable during 9AM-5PM, GMT).</p>
                    <p>It's important to note that the "Due Date" for an order is only an approximation, and we are unable to guarantee a delivery within 14-21 days. This is the average timeframe. Some orders can take up to 30-days before final delivery, due to circumstances outside of our control - such as liasing with webmasters.</p>

                    <p className="title">Order cancellation and amendments</p>
                    <p>Once the order has been placed and payment taken from your account, you will be unable to cancel your order and will not be eligible for a refund. To change or cancel your order please contact our Customer Support team to attempt to resolve your request.</p>

                    <p className="title">Disclaimer of Liability</p>
                    <p>The material displayed on this Website is provided without any guarantees, conditions or warranties as to its accuracy. Unless expressly stated to the contrary to the fullest extent permitted by law Rhino Rank and its suppliers, content providers and advertisers hereby expressly exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity and shall not be liable for any damages whatsoever, including but without limitation to any direct, indirect, special, consequential, punitive or incidental damages, or damages for loss of use, profits, data or other intangibles, damage to goodwill or reputation, or the cost of procurement of substitute goods and services, arising out of or related to the use, inability to use, performance or failures of this Website or the Linked Sites and any materials posted thereon, irrespective of whether such damages were foreseeable or arise in contract, tort, equity, restitution, by statute, at common law or otherwise. This does not affect Rhino Rank’s liability for death or personal injury arising from its negligence, fraudulent misrepresentation, misrepresentation as to a fundamental matter or any other liability which cannot be excluded or limited under applicable law.</p>

                    <p className="title">Linking to this Website</p>
                    <p>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link from any website that is not owned by you. This Website must not be framed on any other site, nor may you create a link to any part of this Website other than the home page. We reserve the right to withdraw linking permission without notice.</p>

                    <p className="title">Disclaimer as to ownership of trademarks, images of personalities and third party copyright</p>
                    <p>Except where expressly stated to the contrary all persons (including their names and images), third party trademarks and content, services and/or locations featured on this Website are in no way associated, linked or affiliated with Rhino Rank and you should not rely on the existence of such a connection or affiliation. Any trade marks/names featured on this Website are owned by the respective trademark owners. Where a trademark or brand name is referred to it is used solely to describe or identify the products and services and is in no way an assertion that such products or services are endorsed by or connected to Rhino Rank.</p>

                    <p className="title">Indemnity</p>
                    <p>You agree to indemnify, defend and hold harmless Rhino Rank, its directors, officers, employees, consultants, agents, and affiliates, from any and all third party claims, liability, damages and/or costs (including, but not limited to, legal fees) arising from your use this Website or your breach of the Terms of Service.</p>

                    <p className="title">Variation</p>
                    <p>Rhino Rank shall have the right in its absolute discretion at any time and without notice to amend, remove or vary the Services and/or any page of this Website.</p>

                    <p className="title">Invalidity</p>
                    <p>If any part of the Terms of Service is unenforceable (including any provision in which we exclude our liability to you) the enforceability of any other part of the Terms of Service will not be affected all other clauses remaining in full force and effect. So far as possible where any clause/sub-clause or part of a clause/sub-clause can be severed to render the remaining part valid, the clause shall be interpreted accordingly. Alternatively, you agree that the clause shall be rectified and interpreted in such a way that closely resembles the original meaning of the clause /sub-clause as is permitted by law.</p>

                    <p className="title">Complaints</p>
                    <p>We operate a complaints handling procedure which we will use to try to resolve disputes when they first arise, please let us know if you have any complaints or comments.</p>

                    <p className="title">Waiver</p>
                    <p>If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.</p>

                    <p className="title">Entire Agreement</p>
                    <p>The above Terms of Service constitute the entire agreement of the parties and supersede any and all preceding and contemporaneous agreements between you and Rhino Rank. Any waiver of any provision of the Terms of Service will be effective only if in writing and signed by a Director of Rhino Rank.</p>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    <Button onClick={handleTermsConditionAccept} color="success" variant="contained">I Agree</Button>
                </DialogActions>
            </Dialog>
            */}

            {/* loading */}
            {formLoading || googleReCaptchaLoading ? <LoadingOver /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Register;
