import React, {useRef, useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import AlertAction from "../AlertAction";
import LoadingOver from "../LoadingOver/LoadingOver";
import {Button} from "@mui/material";
import OrderApi from "../../services/OrderApi";
import './StripeCheckoutForm.css';
import {Navigate} from "react-router";

//--------------------------------------------------

function StripeCheckoutForm(props)
{
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [isSeccess, setIsSeccess] = useState(false);

    const alertAction = useRef(); // init alert

    // send data to strip
    const handleSubmit = async (event) =>
    {
        setLoading(true); // show loading

        event.preventDefault();

        if(!stripe || !elements) { return; }

        const {error} = await stripe.confirmPayment({elements, redirect: 'if_required'});

        if(error)
        {
            alertAction.current.showAlert(error.message); // show error
            setLoading(false); // hide loading
        }
        else
        {
            // alertAction.current.showAlert("Success", "success"); // show success
            // setLoading(false); // hide loading

            // send data
            const params =
                {
                    'orderNumber': props.orderNumber,
                    'clientSecret': props.clientSecret,
                };
            OrderApi.stripePaymentCheck(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Pay Succeeded", "success"); // show success

                        setIsSeccess(true);
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setLoading(false); // hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
    };

    // redirect to panel if user loggedIn
    if(isSeccess)
    {
        return <Navigate to={"/order/payment/response/successful-" + props.orderNumber} />;
    }

    //
    return (
        <>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                {/*<button disabled={!stripe} className="btn-stripe-submit">Submit</button>*/}
                <Button color="primary" variant="contained" type="submit" className="btn-stripe-submit">Pay ${props.price} with strip</Button>
            </form>


            {/* loading */}
            {loading ? <LoadingOver /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    )
}

export default StripeCheckoutForm;
