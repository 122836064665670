import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useParams} from "react-router-dom";
import SortTable from "../../components/SortTable/SortTable";
import Pagination from "../../components/Pagination/Pagination";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import General from "../../utils/General";
import {Button, Grid, TextField} from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InvoiceApi from "../../services/InvoiceApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Invoice.css";

//--------------------------------------------------

function Invoice(props)
{
    // get page type
    let { pageType } = useParams();

    // set page title & page path
    const pageTitle = Menus.viewInvoices.title;
    const pageClassName = Menus.viewInvoices.className;
    usePage().pageData({title: pageTitle, activeMenu: pageClassName});

    //
    const [orderData, setOrderData] = useState([]);
    const [orderDataLoading, setOrderDataLoading] = useState(true);
    const [orderDownloadLoading, setOrderDownloadLoading] = useState(false);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const [searchWord, setSearchWord] = useState('');

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setOrderData(null); // set data to null
        setOrderDataLoading(true); // show loading

        // get data
        InvoiceApi.find({ pageNumber: pageNumber, type: pageType, sortColName: sortColName, sortType: sortType, searchWord: searchWord }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setOrderData(response.data.list);
                }

                setOrderDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setOrderDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, [sortColName, sortType, pageType]);

    // search
    const handleSearch = (e) =>
    {
        loadData();
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    // download pdf
    const downloadPdf = (orderNumber) =>
    {
        setOrderDownloadLoading(true); // show loading

        // get file
        InvoiceApi.pdf({ orderNumber: orderNumber }).then
        (
            function(response)
            {
                const blob = new Blob([response], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'invoice-' + orderNumber + '.pdf';
                link.click();

                setOrderDownloadLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setOrderDownloadLoading(false); // hide loading
            }
        );
    };

    //
    return (
        <div className="page-content page-orders">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/my-profile">Profile</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>

                <div className="search-box form-box">
                    <TextField placeholder="Search" className="form-input" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
                    <SearchOutlinedIcon className="icon" onClick={handleSearch} />
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    orderDataLoading ?
                        null
                        :
                        (
                            orderData !== null && orderData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th onClick={() => changeSortTable('orderNumber')}><span className="col-value"><SortTable sortData={sortData} colName="orderNumber" />Order Number</span></th>
                                        <th onClick={() => changeSortTable('service')} className="w-180"><span className="col-value"><SortTable sortData={sortData} colName="service" />service</span></th>
                                        <th onClick={() => changeSortTable('dateOrdered')} className="w-180"><span className="col-value"><SortTable sortData={sortData} colName="dateOrdered" />Date Ordered</span></th>
                                        <th onClick={() => changeSortTable('dueDate')} className="w-180"><span className="col-value"><SortTable sortData={sortData} colName="dueDate" />Due Date</span></th>
                                        <th onClick={() => changeSortTable('status')} className="w-160"><span className="col-value"><SortTable sortData={sortData} colName="status" />Status</span></th>
                                        <th className="w-140">Download Invoice</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        orderData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.orderNumber}</td>
                                                        <td>{item.service}</td>
                                                        <td>{item.dateOrdered}</td>
                                                        <td>{item.dueDate !== '' ? item.dueDate : (item.canPayment ? 'Awaiting Payment' : '')}</td>
                                                        <td><label className={'label-status ' + General.initStatusClass(item.statusTitle)}>{item.statusTitle}</label></td>
                                                        {/*<td><a href={General.siteUrl + '/invoice/pdf/' + item.orderNumber} target="_blank" color="primary" variant="text">Get Invoice</a></td>*/}
                                                        <td><Button onClick={() => downloadPdf(item.orderNumber)} color="primary" variant="text">Get Invoice</Button></td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="10"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* loading */}
            {orderDataLoading || orderDownloadLoading ? <LoadingOver /> : null}
        </div>
    );
}

export default Invoice;
