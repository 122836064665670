import RestApi from "./RestApi";

export default
{
    send: async function(params) { return RestApi.rest("/ticket/send", params, true); },
    find: async function(params) { return RestApi.rest("/ticket/list", params, true); },
    view: async function(params) { return RestApi.rest("/ticket/view", params, true); },
    answer: async function(params) { return RestApi.rest("/ticket/answer", params, true); },
    close: async function(params) { return RestApi.rest("/ticket/close", params, true); },
}
