import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useParams} from "react-router-dom";
import SortTable from "../../components/SortTable/SortTable";
import Pagination from "../../components/Pagination/Pagination";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Loading from "../../components/Loading/Loading";
import General from "../../utils/General";
import {Button, Grid, TextField} from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TransactionApi from "../../services/TransactionApi";
import "./TransactionHistory.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";

//--------------------------------------------------

function TransactionHistory(props)
{
    // get page type
    let { pageType } = useParams();

    // set page title & page path
    const pageTitle = Menus.transactionHistory.title;
    usePage().pageData({title: pageTitle, activeMenu: Menus.transactionHistory.className});

    //
    const [orderData, setOrderData] = useState([]);
    const [orderDataLoading, setOrderDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const [searchWord, setSearchWord] = useState('');

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setOrderData(null); // set data to null
        setOrderDataLoading(true); // show loading

        // get data
        TransactionApi.find({ pageNumber: pageNumber, type: pageType, sortColName: sortColName, sortType: sortType, searchWord: searchWord }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setOrderData(response.data.list);
                }

                setOrderDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setOrderDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, [sortColName, sortType, pageType]);

    // search
    const handleSearch = (e) =>
    {
        loadData();
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    return (
        <div className="page-content page-transaction-history">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/my-profile">Profile</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>

                <div className="search-box form-box">
                    <TextField placeholder="Search" className="form-input" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
                    <SearchOutlinedIcon className="icon" onClick={handleSearch} />
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    orderDataLoading ?
                            null
                        :
                        (
                            orderData !== null && orderData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th onClick={() => changeSortTable('orderNumber')} className="w-160"><span className="col-value"><SortTable sortData={sortData} colName="orderNumber" />Order Number</span></th>
                                        <th onClick={() => changeSortTable('netTotal')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="netTotal" />Net Total</span></th>
                                        <th>Voucher Discount</th>
                                        <th>Expedited Cost</th>
                                        <th className="w-100">Tax</th>
                                        <th onClick={() => changeSortTable('finalPrice')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="finalPrice" />Total</span></th>
                                        <th onClick={() => changeSortTable('paymentModeTitle')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="paymentModeTitle" />Payment Mode</span></th>
                                        <th onClick={() => changeSortTable('datePayment')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="datePayment" />Date Payment</span></th>
                                        <th>Transactions ID</th>
                                        <th onClick={() => changeSortTable('status')} className="w-120"><span className="col-value"><SortTable sortData={sortData} colName="status" />Status</span></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        orderData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.orderNumber}</td>
                                                        <td>${item.netTotal}</td>
                                                        <td>${item.voucherDiscount}</td>
                                                        <td>${item.expeditedCost}</td>
                                                        <td>${item.tax}</td>
                                                        <td>${item.finalPrice}</td>
                                                        <td>{item.paymentModeTitle}</td>
                                                        <td>{item.datePayment}</td>
                                                        <td>
                                                            {
                                                                item.canPayment ?
                                                                    <Button className="text-transform-unset" color="primary" variant="text" component={Link} to={'/order/payment/' + item.orderNumber}>Payment Pending</Button>
                                                                    :
                                                                    item.transactionsId
                                                            }
                                                        </td>
                                                        <td><label className={'label-status ' + General.initStatusClass(item.statusTitle)}>{item.statusTitle}</label></td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="10"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* loading */}
            {orderDataLoading ? <LoadingOver /> : null}
        </div>
    );
}

export default TransactionHistory;
