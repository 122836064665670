import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from "react-router-dom";
import {Button, Container, Grid, InputLabel, TextField} from "@mui/material";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import TicketApi from "../../services/TicketApi";
import "./New.css";

//--------------------------------------------------

function New(props)
{
    // set page title & page path
    const pageTitle = Menus.ticketNew.title;
    const pageClass = Menus.ticketNew.className;
    usePage().pageData({title: pageTitle, activeMenu: pageClass});

    //
    const [formTicketSubject, setFormTicketSubject] = useState('');
    const [formTicketMessage, setFormTicketMessage] = useState('');
    const [formLoading, setFormLoading] = useState(false);

    const alertAction = useRef(); // init alert

    // send data to server
    const send = () =>
    {
        if(formTicketSubject !== '' && formTicketMessage !== '') // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'subject': formTicketSubject,
                    'message': formTicketMessage
                };
            TicketApi.send(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        setFormTicketSubject('');
                        setFormTicketMessage('');

                        alertAction.current.showAlert("Ticket Sent", "success"); // show success
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    //
    return (
        <div className="page-content page-ticket">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/tickets">Tickets</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <Container className="page-section-container">
                    <Grid container spacing={4}>
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="page-section-box">
                                <div className="section-title">Send new ticket</div>

                                <div className="content">
                                    <div className="form-box">
                                        <div className="form-container">
                                            <Grid container spacing={2} className="form-content">
                                                <Grid item sm={12} xs={12}>
                                                    <InputLabel shrink className="form-label" htmlFor="form-ticket-subject">Subject</InputLabel>
                                                    <TextField fullWidth={true} className="form-input" id="form-ticket-subject" placeholder="Enter Subject" value={formTicketSubject} onChange={(e) => setFormTicketSubject(e.target.value)} />
                                                </Grid>

                                                <Grid item sm={12} xs={12}>
                                                    <InputLabel shrink className="form-label" htmlFor="form-ticket-message">Message</InputLabel>
                                                    <TextField fullWidth={true} className="form-input" id="form-ticket-message" placeholder="Enter Message" multiline={true} value={formTicketMessage} onChange={(e) => setFormTicketMessage(e.target.value)} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Button onClick={send} color="primary" variant="contained">Send</Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>


                {/* loading */}
                {formLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>
        </div>
    );
}

export default New;
