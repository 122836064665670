import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Container, Grid, InputLabel, TextField} from "@mui/material";
import "./View.css";
import TicketApi from "../../services/TicketApi";
import General from "../../utils/General";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import AlertAction from "../../components/AlertAction";
import DialogQuestion from "../../components/DialogQuestion/DialogQuestion";

//--------------------------------------------------

function View(props)
{
    // get page type
    let { ticketId } = useParams();

    // set page title & page path
    const pageTitle = 'View Ticket';
    usePage().pageData({title: pageTitle, activeMenu: 'tickets'});

    //
    const [ticketData, setTicketData] = useState([]);
    const [ticketDataLoading, setTicketDataLoading] = useState(true);

    const [formTicketMessage, setFormTicketMessage] = useState('');
    const [formLoading, setFormLoading] = useState(false);

    const alertAction = useRef(); // init alert
    const dialogQuestion = useRef(); // init alert delete

    // load data
    const loadData = () =>
    {
        setTicketData(null); // set data to null
        setTicketDataLoading(true); // show loading

        // get data
        TicketApi.view({ ticketId: ticketId }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setTicketData(response.data);
                }

                setTicketDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setTicketDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // send data to server
    const answer = () =>
    {
        if(formTicketMessage !== '') // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'ticketId': ticketId,
                    'message': formTicketMessage
                };
            TicketApi.answer(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        setFormTicketMessage('');

                        alertAction.current.showAlert("Answer Sent", "success"); // show success

                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    //
    const handleTicketClose = () =>
    {
        dialogQuestion.current.show();
    };

    const ticketClose = () =>
    {
        dialogQuestion.current.hide(); // hide popup
        setTicketDataLoading(true); // show loading

        // get data
        TicketApi.close({ ticketId: ticketId }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    alertAction.current.showAlert("Ticket Closed", "success"); // show success

                    let tmpData = ticketData;
                    tmpData.isClosed = true;
                    setTicketData(tmpData);
                }
                else
                {
                    alertAction.current.showAlert(response.message); // show error
                }

                setTicketDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setTicketDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-ticket-view">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/tickets">Tickets</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    ticketDataLoading ?
                        null
                        :
                        (
                            ticketData !== null ?
                                <Container className="page-section-container">
                                    <Grid container spacing={4}>
                                        <Grid item lg={12}>
                                            <div className="page-section-box">
                                                <div className="section-title">{ticketData.subject}</div>

                                                <div className="content">
                                                    <div className="ticket-data-bar">
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                                                <div className="title">Date Created</div>
                                                                <div className="value">{ticketData.dateCreated}</div>
                                                            </Grid>

                                                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                                                <div className="title">Status</div>
                                                                <div className="value"><label className={'label-status ' + General.initStatusClass(ticketData.statusTitle)}>{ticketData.statusTitle}</label></div>
                                                            </Grid>

                                                            {
                                                                !ticketData.isClosed ?
                                                                    <Grid item lg={4} md={4} sm={6} xs={6}>
                                                                        <div className="btn-close-box"><Button onClick={handleTicketClose} color="error" variant="contained">Close</Button></div>
                                                                    </Grid>
                                                                    :
                                                                    null
                                                            }
                                                        </Grid>
                                                    </div>

                                                    {
                                                        ticketData.messages !== null && ticketData.messages.length > 0 ?
                                                            ticketData.messages.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <div className="message-box">
                                                                            <div className="sender-date">
                                                                                <div className="sender">{item.sender}</div>
                                                                                <div className="date">{item.date}</div>
                                                                            </div>

                                                                            <div className="message">{item.message}</div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                            :
                                                            null
                                                    }

                                                    {
                                                        !ticketData.isClosed ?
                                                            <div className="form-box form-answer-box">
                                                                <div className="form-container">
                                                                    <Grid container spacing={2} className="form-content">
                                                                        <Grid item sm={12} xs={12}>
                                                                            <TextField fullWidth={true} className="form-input" id="form-ticket-message" placeholder="Enter Answer Message" multiline={true} value={formTicketMessage} onChange={(e) => setFormTicketMessage(e.target.value)} />
                                                                        </Grid>

                                                                        <Grid item xs={12}>
                                                                            <Button onClick={answer} color="primary" variant="contained">Send Answer</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Container>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* loading */}
            {formLoading || ticketDataLoading ? <LoadingOver /> : null}


            {/* alert */}
            <AlertAction ref={alertAction} />


            {/* popup close */}
            <DialogQuestion ref={dialogQuestion} accept={ticketClose} title="Close Ticket" des="Are you sure you want to close the ticket?" buttonType="error" buttonAcceptText="Yes, Close Ticket" buttonCloseText="No" />
        </div>
    );
}

export default View;
