import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {Button, Container, Grid, InputLabel, TextField} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import General from "../../utils/General";
import Loading from "../../components/Loading/Loading";
import UserApi from "../../services/UserApi";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import "./Affiliate.css";
import {useAuth} from "../../contexts/Auth";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

//--------------------------------------------------

function Affiliate(props)
{
    // set page title & page path
    const pageTitle = Menus.affiliatesCentral.title;
    usePage().pageData({title: pageTitle, activeMenu: Menus.affiliatesCentral.className});

    const [formLoading, setFormLoading] = useState(false);
    const [formRegisterPaypalEmail, setFormRegisterPaypalEmail] = useState(General.user.paypalEmail);

    const affiliateLink = General.clientUrl + '/register?ref-code=' + General.user.affiliateCode;

    const auth = useAuth();
    const alertAction = useRef(); // init alert

    // send register data to server
    const register = () =>
    {
        if(formRegisterPaypalEmail) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'paypalEmail': formRegisterPaypalEmail
                };
            UserApi.updatePaypal(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Paypal Email Updated", "success"); // show success

                        auth.authentication({user: response.data.user, nameFamily: response.data.nameFamily, token: response.data.token});
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    //
    const copyToClipboard = (str) =>
    {
        navigator.clipboard.writeText(str);

        alertAction.current.showAlert("Copied to clipboard", "success");
    };

    //
    return (
        <div className="page-content page-affiliate">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/my-profile">Profile</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <Container className="page-section-container">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="page-section-box">
                                <div className="section-title">Eire Digital Affiliate Program</div>

                                <div className="content">
                                    <p>Eire Digital offers recurring commission on all users you refer to us, who purchase our Curated Links or Guest Posts.</p>
                                    <p>For anybody you refer to the program, you and referrals, each will receive 10% of all spend on EVERY order your referrals make - whether that's individual links or packages.</p>
                                    <p>We pay out affiliate commissionsto your Eiredigital wallet and you can use it for any of our services.</p>
                                    <p>Once a user clicks your affiliate link, they are given a 60-day window to sign-up to Eire Digital. Once signed up, they will be assigned to your account for life, and you will receive commission on all purchases.</p>
                                    <p>You can not cash out the commission earned from affiliate program.</p>

                                    <Alert severity="success" className="affiliate-box m-t-16">
                                        <div><strong className="m-r-8">Your Affiliate ID:</strong>{General.user.affiliateCode}</div>

                                        <Tooltip title="Copy Affiliate Code" placement="top" arrow><ContentCopyIcon className="btn-copy" onClick={() => copyToClipboard(General.user.affiliateCode)} /></Tooltip>
                                    </Alert>

                                    <Alert severity="info" className="affiliate-box m-t-16">
                                        <div><strong className="m-r-8">Your Affiliate Link:</strong>{affiliateLink}</div>

                                        <Tooltip title="Copy Affiliate Link" placement="top" arrow><ContentCopyIcon className="btn-copy" onClick={() => copyToClipboard(affiliateLink)} /></Tooltip>
                                    </Alert>
                                </div>
                            </div>
                        </Grid>

                        {/*
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="page-section-box">
                                <div className="section-title">Ensure All Fields Are Filled To Generate Affiliate ID</div>

                                <div className="content">
                                    <p>With an affiliate ID stand to earn commissions from all signups and any purchases they make for life* Please read through the affiliate terms of services before signing up.</p>

                                    {
                                        General.user.billingAddress === null || General.user.billingAddress === '' ?
                                            <Alert severity="error" className="m-t-16">
                                                <AlertTitle className="alert-title">Your profile is incomplete. Please correct the following issues and come back to this page to generate an affiliate ID.</AlertTitle>
                                                <p>- Billing Address missing</p>
                                                <Button component={Link} to="/my-profile/edit" variant="contained" color="error" className="text-transform-unset m-t-8" startIcon={<EditIcon />}>Edit Profile</Button>
                                            </Alert>
                                            :
                                            <>
                                                <Alert severity="success" className="m-t-16"><strong className="m-r-8">Your Affiliate ID:</strong> {General.user.affiliateCode}</Alert>

                                                <div className="form-box w-max-300 m-t-32">
                                                    <div className="form-container">
                                                        <Grid container spacing={2} className="form-content">
                                                            <Grid item sm={12} xs={12}>
                                                                <InputLabel shrink className="form-label" htmlFor="form-register-paypal-email">Paypal Email</InputLabel>
                                                                <TextField fullWidth={true} className="form-input" id="form-register-paypal-email" placeholder="Enter Paypal Email address" value={formRegisterPaypalEmail} onChange={(e) => setFormRegisterPaypalEmail(e.target.value)} />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <div className="m-t-16">
                                                                    <Button onClick={register} color="primary" variant="contained" className="m-r-8" startIcon={<AutorenewIcon />}>Submit Changes</Button>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </Grid>
                        */}
                    </Grid>
                </Container>


                {/* loading */}
                {formLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>
        </div>
    );
}

export default Affiliate;
