import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

export default
{
    orderSection: {title: "ORDERS", isMenu: false},
    orderNew: {className: "order-new", link: "/order/new", title: "New Order", icon: <AddIcon className="icon" />, isMenu: true},
    ordersActive: {className: "orders-active", link: "/orders/active", title: "Active Orders", icon: <ListAltOutlinedIcon className="icon" />, isMenu: true},
    ordersCompleted: {className: "orders-completed", link: "/orders/completed", title: "Completed Orders", icon: <Inventory2OutlinedIcon className="icon" />, isMenu: true},
    ordersCancelled: {className: "orders-cancelled", link: "/orders/cancelled", title: "Cancelled Orders", icon: <DeleteOutlineIcon className="icon" />, isMenu: true},

    supportSection: {title: "SUPPORT", isMenu: false},
    ticketNew: {className: "ticket-new", link: "/ticket/new", title: "New Ticket", icon: <AddCommentOutlinedIcon className="icon" />, isMenu: true},
    tickets: {className: "tickets", link: "/tickets", title: "Tickets", icon: <ForumOutlinedIcon className="icon" />, isMenu: true},

    accountSection: {title: "MY ACCOUNT", isMenu: false},
    myProfile: {className: "my-profile", link: "/my-profile", title: "My Profile", icon: <PersonOutlineIcon className="icon" />, isMenu: true},
    wallet: {className: "wallet", link: "/wallet", title: "Wallet", icon: <AccountBalanceWalletOutlinedIcon className="icon" />, isMenu: true},
    transactionHistory: {className: "transaction-history", link: "/transaction-history", title: "Transaction History", icon: <ReceiptLongOutlinedIcon className="icon" />, isMenu: true},
    viewInvoices: {className: "view-invoices", link: "/view-invoices", title: "View Invoices", icon: <DescriptionOutlinedIcon className="icon" />, isMenu: true},
    affiliatesCentral: {className: "affiliates-central", link: "/affiliates-central", title: "Affiliates Central", icon: <GroupOutlinedIcon className="icon" />, isMenu: true},
};
