import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, Navigate, useParams} from "react-router-dom";
import {Button, Container, Grid, InputLabel, TextField} from "@mui/material";
import OrderApi from "../../services/OrderApi";
import General from "../../utils/General";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import imagePaypalLogo from '../../images/paypal-logo.png';
import imageStripeLogo from '../../images/stripe-logo.png';
import Alert from '@mui/material/Alert';
import "./Payment.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import AlertAction from "../../components/AlertAction";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckoutForm from '../../components/StripeCheckoutForm/StripeCheckoutForm';

//--------------------------------------------------

function Payment(props)
{
    // get page type
    let { orderNumber, responseType } = useParams();

    // set page title & page path
    const pageTitle = 'Payment Order';
    const [activeMenu, setActiveMenu] = useState('');
    usePage().pageData({title: pageTitle, activeMenu: activeMenu});

    //
    const [orderData, setOrderData] = useState([]);
    const [orderDataLoading, setOrderDataLoading] = useState(true);
    const [formCouponCode, setFormCouponCode] = useState('');
    const [formLoading, setFormLoading] = useState(false);
    const [isPaid, setIsPaid] = useState(false);

    const [stripeShow, setStripeShow] = useState(false);
    const [stripeOption, setStripeOption] = useState('');
    const stripePromise = loadStripe('pk_test_51LZNcvHMy6LKjttogEKIRQNZTSIDdPmnXC8wkSan3JZSokls67alhYVO96XZwzo6Hjy88V1hn4PDZlTLBhdKMiZT00ZfGtaWy2');

    let paidByWallet = false;

    const alertAction = useRef(); // init alert

    // load data
    const loadData = () =>
    {
        if(orderNumber !== '')
        {
            setOrderData(null); // set data to null
            setOrderDataLoading(true); // show loading

            // get data
            OrderApi.paymentSummery({ orderNumber: orderNumber }).then
            (
                function(response)
                {
                    if (response.status === 200)
                    {
                        setOrderData(response.data);
                        setStripeOption({clientSecret: response.data.stripeClientSecret, appearance: {theme: 'strip'}});

                        switch(response.data.statusId)
                        {
                            case General.orderStatusUnpaid:
                            case General.orderStatusPaid:
                            case General.orderStatusPaymentFailed: setActiveMenu('orders-active'); break;
                            case General.orderStatusCompleted: setActiveMenu('orders-completed'); break;
                            case General.orderStatusCancelled: setActiveMenu('orders-cancelled'); break;
                        }
                    }

                    setOrderDataLoading(false); // hide loading
                }
            ).catch
            (
                function(error)
                {
                    setOrderDataLoading(false); // hide loading
                }
            );
        }
    };

    useEffect(() => { loadData(); }, [responseType]);

    // send data to server
    const couponSave = () =>
    {
        if(formCouponCode !== '') // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params =
                {
                    'orderNumber': orderNumber,
                    'couponCode': formCouponCode
                };
            OrderApi.couponSave(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Coupon Saved", "success"); // show success
                        alertAction.current.showAlert("The cost of the order has been paid in full", "success"); // show success

                        setFormCouponCode('');
                        setIsPaid(response.isPaid);
                        setOrderData(response.data);
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // send data to server
    const handlePaymentByWallet = () =>
    {
        setFormLoading(true); // hide btn & show loading

        // send data
        const params = {'orderNumber': orderNumber};
        OrderApi.paymentWallet(params).then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("The cost of the order has been paid by wallet", "success"); // show success

                    paidByWallet = true;
                    loadData();
                }
                else
                {
                    alertAction.current.showAlert(response.message); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    // redirect to list
    if(isPaid)
    {
        return <Navigate to={'/orders'} />;
    }

    //
    return (
        <div className="page-content page-order-payment">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/orders">Orders</Link>
                        {
                            orderData !== null ?
                                (() =>
                                {
                                    switch(orderData.statusId)
                                    {
                                        case General.orderStatusUnpaid:
                                        case General.orderStatusPaid:
                                        case General.orderStatusPaymentFailed: return <Link className="link" underline="hover" color="inherit" to="/orders/active">Active Orders</Link>;
                                        case General.orderStatusCompleted: return <Link className="link" underline="hover" color="inherit" to="/orders/completed">Completed Orders</Link>;
                                        case General.orderStatusCancelled: return <Link className="link" underline="hover" color="inherit" to="/orders/cancelled">Cancelled Orders</Link>;
                                        default: return null;
                                    }
                                })()
                                :
                                null
                        }
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    (() =>
                    {
                        switch(responseType)
                        {
                            case 'successful': return <Alert variant="filled" severity="success" className="m-b-16">Payment has been made successfully.</Alert>;
                            case 'cancelled': return <Alert variant="filled" severity="warning" className="m-b-16">The payment has been canceled by you.</Alert>;
                            case 'error': return <Alert variant="filled" severity="error" className="m-b-16">There is a problem with payment.</Alert>;
                            case 'error-data': return <Alert variant="filled" severity="error" className="m-b-16">The order information is incorrect.</Alert>;
                            default: return null;
                        }
                    })()
                }

                {
                    orderNumber !== '' ?
                        (
                            orderDataLoading ?
                                null
                                :
                                (
                                    orderData !== null ?
                                        <Container className="page-section-container">
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <div className="page-section-box">
                                                        <div className="section-title">Order #{orderData.orderNumber} Payment Summary</div>

                                                        <div className="content">
                                                            {
                                                                orderData.canPayment && orderData.discountCoupon === 0 ?
                                                                    <div className="form-box form-coupon">
                                                                        <TextField fullWidth={true} className="form-input" id="form-coupon-code" placeholder="Enter Coupon Code" value={formCouponCode} onChange={(e) => setFormCouponCode(e.target.value)} />
                                                                        <Button onClick={couponSave} color="info" variant="contained">Save Coupon</Button>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            <table className="table-data">
                                                                <tbody>
                                                                <tr>
                                                                    <td>Net Total</td><td>${orderData.netTotal}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Discount</td><td>${orderData.discount}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Coupon Discount</td><td>${orderData.discountCoupon}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Vat @ 20% (if applicable)</td><td>${orderData.tax}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Pay By Wallet</td><td>${orderData.payWallet}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Total</td><td>${orderData.finalPrice}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>

                                                            {
                                                                orderData.canPayment ?
                                                                    <div className="payment-box">
                                                                        <div className="title">Proceed To Pay</div>

                                                                        <div className="payment-gateway">
                                                                            {
                                                                                orderData.hasWallet ?
                                                                                    <div className="payment-gateway-item" onClick={handlePaymentByWallet}>
                                                                                        <div className="des">Pay using</div>
                                                                                        <div className="gateway-logo-title">Wallet</div>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            <div className="payment-gateway-item" onClick={() => setStripeShow(!stripeShow)}>
                                                                                <div className="des">Pay using</div>
                                                                                <img src={imageStripeLogo} className="gateway-logo" alt="stripe" />
                                                                            </div>

                                                                            <a className="payment-gateway-item" href={General.siteUrl + '/paypal/payment/' + orderNumber}>
                                                                                <div className="des">Pay using</div>
                                                                                <img src={imagePaypalLogo} className="gateway-logo" alt="paypal" />
                                                                            </a>
                                                                        </div>

                                                                        {/*<a className="paypal-box" href={General.siteUrl + '/paypal/payment/' + orderNumber}>*/}
                                                                        {/*    <div className="des-box">*/}
                                                                        {/*        <div className="des-1">Pay using PayPal</div>*/}
                                                                        {/*        <div className="des-2">Fast, easy and secure</div>*/}
                                                                        {/*    </div>*/}

                                                                        {/*    <img src={imagePaypalLogo} className="logo-paypal" alt="paypal" />*/}
                                                                        {/*</a>*/}

                                                                        <div className={stripeShow ? 'payment-gateway-stripe' : 'display-none'}>
                                                                            <Elements stripe={stripePromise} options={stripeOption}>
                                                                                <StripeCheckoutForm price={orderData.finalPrice} orderNumber={orderNumber} clientSecret={orderData.stripeClientSecret} />
                                                                            </Elements>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                !orderData.canPayment && orderData.typeId === 2 ?
                                                                    <div className="text-center m-t-32">
                                                                        <Button variant="contained" color="error" component={Link} to={'/order/view/' + orderNumber}>go to Send Text File</Button>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                        :
                                        <AlertDataEmpty />
                                )
                        )
                        :
                        null
                }
            </Scrollbars>


            {/* loading */}
            {orderDataLoading || formLoading ? <LoadingOver /> : null}


            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Payment;
