import RestApi from "./RestApi";

export default
{
    register: async function(params) { return RestApi.rest("/register", params, false); },
    login: async function(params) { return RestApi.rest("/login", params, false); },
    loginSocial: async function(params) { return RestApi.rest("/login-social", params, false); },
    logout: async function() { return RestApi.rest("/logout"); },
    forgotPassword: async function(params) { return RestApi.rest("/forgot-password", params, false); },
    update: async function(params) { return RestApi.rest("/user/update", params, true); },
    updatePaypal: async function(params) { return RestApi.rest("/paypal/update", params, true); },
    confirm: async function() { return RestApi.rest("/user/confirm"); },
}
