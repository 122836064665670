import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
// routers
import RouterPublic from "./RouterPublic";
import RouterPrivate from './RouterPrivate';
// pages
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import PageNotFound from '../pages/PageNotFound';
import OrderNew from "../pages/Order/New";
import LinkInsertion from "../pages/Order/LinkInsertion";
import GuestPost from "../pages/Order/GuestPost";
import OrderView from "../pages/Order/View";
import Payment from "../pages/Order/Payment";
import Orders from "../pages/Order/Orders";
import Profile from "../pages/Profile/Profile";
import TransactionHistory from "../pages/TransactionHistory/TransactionHistory";
import Invoice from "../pages/Invoice/Invoice";
import Affiliate from "../pages/Affiliate/Affiliate";
import Activation from "../pages/Activation/Activation";
import Wallet from "../pages/Wallet/Wallet";
import Tickets from "../pages/Ticket/Tickets";
import TicketNew from "../pages/Ticket/New";
import TicketView from "../pages/Ticket/View";
import AnalyseRequest from "../pages/Order/AnalyseRequest";

//--------------------------------------------------

function Router()
{
    return (
        <Routes>
            <Route element={<RouterPublic />}>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route path="/activation/:messageType" element={<Activation />} />

            <Route exact path='/' element={<Navigate to="/order/new" />} />
            <Route exact path='/orders' element={<Navigate to="/orders/active" />} />

            <Route element={<RouterPrivate />}>
                <Route path="/order/new" element={<OrderNew />} />
                <Route path="/order/link-insertion" element={<LinkInsertion />} />
                <Route path="/order/guest-post" element={<GuestPost />} />
                <Route path="/order/analyse-request" element={<AnalyseRequest />} />
                <Route path="/order/view/:orderNumber" element={<OrderView />} />
                <Route path="/order/payment/:orderNumber" element={<Payment />} />
                <Route path="/order/payment/response/:responseType" element={<Payment />} />
                <Route path="/order/payment/response/:responseType-:orderNumber" element={<Payment />} />
                <Route path="/orders/:pageType" element={<Orders />} />

                <Route path="/ticket/new" element={<TicketNew />} />
                <Route path="/tickets" element={<Tickets />} />
                <Route path="/ticket/view/:ticketId" element={<TicketView />} />

                <Route path="/my-profile" element={<Profile />} />
                <Route path="/my-profile/:tabActive" element={<Profile />} />

                <Route path="/transaction-history" element={<TransactionHistory />} />

                <Route path="/view-invoices" element={<Invoice />} />

                <Route path="/wallet" element={<Wallet />} />

                <Route path="/affiliates-central" element={<Affiliate />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default Router;
