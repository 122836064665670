import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useNavigate, useParams} from "react-router-dom";
import SortTable from "../../components/SortTable/SortTable";
import Pagination from "../../components/Pagination/Pagination";
import DialogQuestion from "../../components/DialogQuestion/DialogQuestion";
import OrderApi from "../../services/OrderApi";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import Loading from "../../components/Loading/Loading";
import General from "../../utils/General";
import ClearIcon from '@mui/icons-material/Clear';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Button, Grid, TextField} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import "./Orders.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";

//--------------------------------------------------

function Orders(props)
{
    // get page type
    let { pageType } = useParams();

    // set page title & page path
    let menuData;
    if(pageType === 'active')
    {
        menuData = Menus.ordersActive;
    }
    else if(pageType === 'completed')
    {
        menuData = Menus.ordersCompleted;
    }
    else if(pageType === 'cancelled')
    {
        menuData = Menus.ordersCancelled;
    }
    else
    {
        window.location.href = '/order/new';
    }

    const pageTitle = menuData.title;
    const pageClassName = menuData.className;
    usePage().pageData({title: pageTitle, activeMenu: pageClassName});

    //
    const [orderData, setOrderData] = useState([]);
    const [orderDataLoading, setOrderDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const [formOrderTagDialogOpen, setFormOrderTagDialogOpen] = useState(false);
    const [formOrderTagLoading, setFormOrderTagLoading] = useState(false);
    const [formOrderTagOrderNumber, setFormOrderTagOrderNumber] = useState('');
    const [formOrderTagTags, setFormOrderTagTags] = useState('');
    const [formOrderTagIndex, setFormOrderTagIndex] = useState('');

    const [orderNumber, setOrderNumber] = useState('');
    const [searchWord, setSearchWord] = useState('');

    const navigate = useNavigate();
    const alertAction = useRef(); // init alert
    const dialogQuestion = useRef(); // init alert delete

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setOrderData(null); // set data to null
        setOrderDataLoading(true); // show loading

        // get data
        OrderApi.find({ pageNumber: pageNumber, type: pageType, sortColName: sortColName, sortType: sortType, searchWord: searchWord }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setOrderData(response.data.list);
                    // setOrderData([...response.data.list, ...[]]);
                }

                setOrderDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setOrderDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { setSearchWord(''); loadData(); }, [sortColName, sortType, pageType]);

    // search
    const handleSearch = (e) =>
    {
        loadData();
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    const handleOrderCancel = (on) =>
    {
        dialogQuestion.current.show();

        setOrderNumber(on); // set order number for delete
    };

    const orderCancel = () =>
    {
        dialogQuestion.current.hide(); // hide popup
        setOrderDataLoading(true); // show loading

        // get data
        OrderApi.cancel({ orderNumber: orderNumber }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    alertAction.current.showAlert("Order Cancelled", "success"); // show success

                    const pn = General.initPageNumber(paginationData);
                    if(pn > 0)
                    {
                        loadData(pn);
                    }
                    else
                    {
                        setOrderData(null);
                        setOrderDataLoading(false); // hide loading
                    }
                }
                else
                {
                    // alertAction.current.showAlert("Incorrect information"); // show error
                    alertAction.current.showAlert(response.message); // show error

                    setOrderDataLoading(false); // hide loading
                }
            }
        ).catch
        (
            function(error)
            {
                setOrderDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    const handleFormOrderTagDialog = (index = -1) =>
    {
        setFormOrderTagDialogOpen(!formOrderTagDialogOpen);

        setFormOrderTagIndex((index >= 0) ? index : -1);
        setFormOrderTagOrderNumber((index >= 0) ? orderData[index].orderNumber : 0);
        setFormOrderTagTags((index >= 0 && orderData[index].tags !== null) ? orderData[index].tags.join(', ') : '');
    };

    const handleFormOrderTagSave = () =>
    {
        setFormOrderTagLoading(true); // show loading

        // get data
        OrderApi.tagSave({ orderNumber: formOrderTagOrderNumber, tags: formOrderTagTags }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    alertAction.current.showAlert("Tags Saved", "success"); // show success

                    let tmpData = orderData;
                    tmpData[formOrderTagIndex].tags = formOrderTagTags.split(',');
                    setOrderData([...tmpData, ...[]]);

                    handleFormOrderTagDialog();
                }
                else
                {
                    // alertAction.current.showAlert("Incorrect information"); // show error
                    alertAction.current.showAlert(response.message); // show error
                }

                setFormOrderTagLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormOrderTagLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    const goToOrderView = (on) =>
    {
        navigate("/order/view/" + on, { replace: false });
    };

    //
    return (
        <div className="page-content page-orders">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/orders">Orders</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>

                <div className="search-box form-box">
                    <TextField placeholder="Search" className="form-input" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
                    <SearchOutlinedIcon className="icon" onClick={handleSearch} />
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    orderDataLoading ?
                        null
                        :
                        (
                            orderData !== null && orderData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th onClick={() => changeSortTable('orderNumber')} className="w-160"><span className="col-value"><SortTable sortData={sortData} colName="orderNumber" />Order Number</span></th>
                                        <th onClick={() => changeSortTable('service')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="service" />service</span></th>
                                        <th onClick={() => changeSortTable('dateOrdered')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="dateOrdered" />Date Ordered</span></th>
                                        {pageType !== 'completed' ? <th onClick={() => changeSortTable('dueDate')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="dueDate" />Due Date</span></th> : null}
                                        {pageType === 'completed' ? <th onClick={() => changeSortTable('dateCompleted')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="dateCompleted" />Date Completed</span></th> : null}
                                        <th>Tags</th>
                                        <th onClick={() => changeSortTable('status')} className="w-120"><span className="col-value"><SortTable sortData={sortData} colName="status" />Status</span></th>
                                        <th className="w-120">Action</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        orderData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.orderNumber}</td>
                                                        <td>{item.service}</td>
                                                        <td>{item.dateOrdered}</td>
                                                        {pageType !== 'completed' ? <td>{item.dueDate}</td> : null}
                                                        {pageType === 'completed' ? <td>{item.dateCompleted}</td> : null}
                                                        <td>
                                                            {
                                                                item.tags != null && item.tags.length > 0 ?
                                                                    item.tags.map
                                                                    (
                                                                        (tag, index2) =>
                                                                        {
                                                                            return (
                                                                                <label key={index2} className="label-tag">{tag}</label>
                                                                            );
                                                                        }
                                                                    )
                                                                    :
                                                                    null
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.canPayment ?
                                                                    <Link to={'/order/payment/' + item.orderNumber}><label className={'label-status link ' + General.initStatusClass(item.statusTitle)}>{item.statusTitle}</label></Link>
                                                                    :
                                                                    <label className={'label-status ' + General.initStatusClass(item.statusTitle)}>{item.statusTitle}</label>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Tooltip title="View Order" placement="top" arrow><IconButton color="primary" onClick={() => goToOrderView(item.orderNumber)}><RemoveRedEyeOutlinedIcon /></IconButton></Tooltip>
                                                            {item.canCancel ? <Tooltip title="Cancel Order" placement="top" arrow><IconButton color="error" onClick={() => handleOrderCancel(item.orderNumber)}><ClearIcon /></IconButton></Tooltip> : null}
                                                            {/*<Tooltip title="Duplicate Order" placement="top" arrow><IconButton color="default"><ContentCopyIcon /></IconButton></Tooltip>*/}
                                                            <Tooltip title="Manage Order Tags" placement="top" arrow><IconButton color="info" onClick={() => handleFormOrderTagDialog(index)}><LocalOfferOutlinedIcon /></IconButton></Tooltip>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="10"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }


                {/* form order tag */}
                <Dialog open={formOrderTagDialogOpen} onClose={handleFormOrderTagDialog} fullWidth={true} maxWidth='xs'>
                    <DialogTitle>
                        <div className="dialog-title">
                            <span className="title">Manage Tags</span>
                            <IconButton className="dialog-close" onClick={handleFormOrderTagDialog}><CloseIcon className="icon" /></IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent className="dialog-content form-box form-box-t2">
                        <Grid item xs={12}>
                            <TextField className="form-input" multiline={true} id="form-order-tag-tags" placeholder="Add tags to quickly search and filter your orders" value={formOrderTagTags} onChange={(e) => setFormOrderTagTags(e.target.value)} />
                        </Grid>
                    </DialogContent>

                    <DialogActions className="dialog-actions">
                        <Button onClick={handleFormOrderTagSave} color="primary" variant="contained">Save Tags</Button>
                        <Button onClick={handleFormOrderTagDialog} color="primary" variant="outlined">Cancel</Button>
                    </DialogActions>
                </Dialog>


                {/* loading */}
                {orderDataLoading || formOrderTagLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />


                {/* popup delete */}
                <DialogQuestion ref={dialogQuestion} accept={orderCancel} title="Cancel Order" des="Are you sure you want to cancel the order?" buttonType="error" buttonAcceptText="Yes, Cancel Order" buttonCloseText="No" />
            </Scrollbars>
        </div>
    );
}

export default Orders;
