import image404 from '../images/404.png';
import {usePage} from "../contexts/Page";
import '../styles/Main.css';
import React from "react";

//--------------------------------------------------

function PageNotFound(props)
{
    // set page title & page path
    usePage().pageData({title: "Page 404", activeMenu: '404'});

    // page content
    return (
        <div className="page-content page-content-404">
            <div className="page-404">
                <img src={image404} alt="404" />
            </div>
        </div>
    );
}

export default PageNotFound;
