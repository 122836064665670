import React, {useCallback, useEffect, useRef, useState} from 'react';
import AlertAction from "../../components/AlertAction";
import UserApi from "../../services/UserApi";
import Loading from "../../components/Loading/Loading";
import { Grid, Container, TextField, Button, InputLabel } from '@mui/material';
import { Link } from "react-router-dom";
import imageLogo from '../../images/logo.png';
import "./ForgotPassword.css";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

//--------------------------------------------------

function ForgotPassword()
{
    const [email, setEmail] = useState("");

    const [formLoading, setFormLoading] = useState(false);
    const [googleReCaptchaLoading, setGoogleReCaptchaLoading] = useState(true);

    const [googleReCaptchaInterval, setGoogleReCaptchaInterval] = useState('');

    const alertAction = useRef(); // init alert

    // reCaptcha
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    // const reloadRecaptcha = useCallback(async () => { generateRecaptcha(); }, [executeRecaptcha]);
    const generateRecaptcha = async () =>
    {
        if(!executeRecaptcha) return;

        const token = await executeRecaptcha('login');
        // console.log(token);
        setGoogleReCaptchaLoading(false);
        setReCaptchaToken(token);
    };

    useEffect(() => { generateRecaptcha(); let grid = setInterval(generateRecaptcha, 90000); setGoogleReCaptchaInterval(grid); }, [executeRecaptcha]);

    // send Forgot Password data to server
    const forgotPassword = () =>
    {
        if(email !== "") // check id & password is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'email': email, 'reCaptchaToken': reCaptchaToken};
            UserApi.forgotPassword(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        clearInterval(googleReCaptchaInterval);

                        alertAction.current.showAlert("Password recovery mail sent.", 'success'); // show success
                    }
                    else
                    {
                        // alertAction.current.showAlert("Incorrect email"); // show error
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading

                    generateRecaptcha();
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error

                    generateRecaptcha();
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Enter a valid email!"); // show error
        }
    };

    // page content
    return (
        <div className="page-forgot-password">
            <Container className="form-box">
                <Grid container justifyContent="center">
                    <Grid item xs={12} className="logo-box"><img src={imageLogo} alt="logo" /></Grid>

                    <Grid item md={6} sm={8} xs={12} className="form-forgot-password">
                        <Grid container className="form-container">
                            <Grid item sm={12} xs={12} className="form-content">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className="form-title-box">
                                        <h2 className="form-title">Reset your Password</h2>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel shrink className="form-label" htmlFor="form-forgot-password-email">Email</InputLabel>
                                        <TextField fullWidth={true} className="form-input" id="form-forgot-password-email" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12} className="m-t-16">
                                        <Button onClick={forgotPassword} fullWidth={true} color="primary" variant="contained">Reset Password</Button>
                                    </Grid>

                                    <Grid item xs={12} className="forgot-password-des">If you need help or are having trouble, contact us immediately by clicking on the blue help button to the bottom left of your screen.</Grid>

                                    <Grid item xs={12} className="forgot-password-link"><Link to="/login" className="link-to-login">Go back to sign in?</Link></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {/* loading */}
            {formLoading || googleReCaptchaLoading ? <LoadingOver /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default ForgotPassword;
