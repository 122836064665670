import React from 'react';
import {Route, Navigate, Outlet} from 'react-router-dom';
import { useAuth } from "../contexts/Auth";

//--------------------------------------------------

function RouterPublic(props)
{
    const isAuthenticated = useAuth().authenticated;

    return <>{ !isAuthenticated ? <Outlet /> : <Navigate to="/order/new"/> }</>;
}

export default RouterPublic;
