import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useParams} from "react-router-dom";
import {Button, Container, Grid} from "@mui/material";
import "./View.css";
import OrderApi from "../../services/OrderApi";
import Loading from "../../components/Loading/Loading";
import General from "../../utils/General";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Input from "@mui/material/Input";
import AlertAction from "../../components/AlertAction";
import InvoiceApi from "../../services/InvoiceApi";

//--------------------------------------------------

function View(props)
{
    // get page type
    let { orderNumber } = useParams();

    // set page title & page path
    const pageTitle = 'View Order';
    const [activeMenu, setActiveMenu] = useState('');
    usePage().pageData({title: pageTitle, activeMenu: activeMenu});

    //
    const [orderData, setOrderData] = useState([]);
    const [orderDataLoading, setOrderDataLoading] = useState(true);
    const [orderFileUploading, setOrderFileUploading] = useState(false);
    const [orderFileDownloading, setOrderFileDownloading] = useState(false);

    const alertAction = useRef(); // init alert

    // load data
    const loadData = () =>
    {
        setOrderData(null); // set data to null
        setOrderDataLoading(true); // show loading

        // get data
        OrderApi.view({ orderNumber: orderNumber }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setOrderData(response.data);

                    switch(response.data.statusId)
                    {
                        case General.orderStatusUnpaid:
                        case General.orderStatusPaid:
                        case General.orderStatusPaymentFailed: setActiveMenu('orders-active'); break;
                        case General.orderStatusCompleted: setActiveMenu('orders-completed'); break;
                        case General.orderStatusCancelled: setActiveMenu('orders-cancelled'); break;
                    }
                }

                setOrderDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setOrderDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    //
    const downloadExcel = () =>
    {
        let csvContent = "data:text/csv;charset=utf-8," + "URL,Anchor,General Niche,Specific Niche,Target Domain,Target Page,Type\n" + orderData.links.map(e => Object.values(e).join(",")).join("\n");
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", orderData.orderNumber + ".csv");
        document.body.appendChild(link);
        link.click();
    };

    // upload text file
    const handleFileUpload = (e) =>
    {
        if(e.target.files !== undefined && e.target.files[0].type === 'text/plain')
        {
            setOrderFileUploading(true); // show loading

            //
            let params = new FormData();
            params.append('orderNumber', orderNumber);
            params.append('textFile', e.target.files[0]);
            OrderApi.fileUpload(params).then
            (
                function(response)
                {
                    if (response.status === 200)
                    {
                        alertAction.current.showAlert("File Uploaded", "success"); // show success
                        loadData();
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setOrderFileUploading(false); // hide loading
                }
            ).catch
            (
                function(error)
                {
                    alertAction.current.showAlert("There is a Problem"); // show error
                    setOrderFileUploading(false); // hide loading
                }
            );
        }
        else
        {
            alertAction.current.showAlert("The selected file is incorrect"); // show error
        }
    };

    const handleFileDownload = () =>
    {
        setOrderFileDownloading(true); // show loading

        // get file
        OrderApi.fileDownload({ orderNumber: orderNumber }).then
        (
            function(response)
            {
                const blob = new Blob([response], { type: 'text/plain' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'order-' + orderNumber + '.txt';
                link.click();

                setOrderFileDownloading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setOrderFileDownloading(false); // hide loading
            }
        );
    };

    //
    return (
        <div className="page-content page-order-view">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Link className="link" underline="hover" color="inherit" to="/orders">Orders</Link>
                        {
                            orderData !== null ?
                                (() =>
                                {
                                    switch(orderData.statusId)
                                    {
                                        case General.orderStatusUnpaid:
                                        case General.orderStatusPaid:
                                        case General.orderStatusPaymentFailed: return <Link className="link" underline="hover" color="inherit" to="/orders/active">Active Orders</Link>;
                                        case General.orderStatusCompleted: return <Link className="link" underline="hover" color="inherit" to="/orders/completed">Completed Orders</Link>;
                                        case General.orderStatusCancelled: return <Link className="link" underline="hover" color="inherit" to="/orders/cancelled">Cancelled Orders</Link>;
                                        default: return null;
                                    }
                                })()
                                :
                                null
                        }
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    orderDataLoading ?
                        null
                        :
                        (
                            orderData !== null && orderData.links !== null && orderData.links.length > 0 ?
                                <Container className="page-section-container">
                                    <Grid container spacing={4}>
                                        <Grid item lg={12}>
                                            <div className="page-section-box">
                                                <div className="section-title">Order #{orderData.orderNumber}</div>
                                                <div className="status-pay-excel">
                                                    <span className={'label-status ' + General.initStatusClass(orderData.statusTitle)}>Status: {orderData.statusTitle}</span>

                                                    <div className="pay-excel">
                                                        {
                                                            (orderData.mustFile) ?
                                                                (
                                                                    !orderData.fileExist ?
                                                                        <label htmlFor="icon-button-file">
                                                                            <Input id="icon-button-file" type="file" className="new-order-input-file-upload" onChange={handleFileUpload} />
                                                                            <Button color="error" variant="contained" component="span" className="text-transform-unset m-r-l-4">Select and Upload Text File(txt: text plain)</Button>
                                                                        </label>
                                                                        :
                                                                        <Button variant="outlined" color="error" className="text-transform-unset m-r-l-4" onClick={handleFileDownload}>Download Text File</Button>
                                                                )
                                                                :
                                                                null
                                                        }
                                                        {orderData.canPayment ? <Button component={Link} to={'/order/payment/' + orderNumber} variant="contained" color="primary" className="text-transform-unset m-r-l-4">Make Payment</Button> : null}
                                                        <Button variant="outlined" color="success" className="m-r-l-4" onClick={downloadExcel}>Download Excel</Button>
                                                    </div>
                                                </div>

                                                <div className="content">
                                                    <table className="table-data">
                                                        <thead className="sticky">
                                                        <tr>
                                                            <th className="w-60">Sr.no</th>
                                                            <th>URL</th>
                                                            <th>Anchor</th>
                                                            <th>General Niche</th>
                                                            <th>Specific Niche</th>
                                                            {/*<th>Target Domain</th>*/}
                                                            <th>Target Page</th>
                                                            <th className="w-160">Type</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {
                                                            orderData.links.map
                                                            (
                                                                (item, index) =>
                                                                {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td><a href={item.url} target="_blank">{item.url}</a></td>
                                                                            <td>{item.anchorText}</td>
                                                                            <td>{item.generalNiche}</td>
                                                                            <td>{item.specificNiche}</td>
                                                                            {/*<td>{item.targetDomain !== '' ? <a href={item.targetDomain} target="_blank">{item.targetDomain}</a> : ''}</td>*/}
                                                                            <td>{item.targetPage !== '' ? <a href={item.targetPage} target="_blank">{item.targetPage}</a> : ''}</td>
                                                                            <td>{item.title}</td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Container>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* loading */}
            {orderDataLoading || orderFileUploading || orderFileDownloading ? <LoadingOver /> : null}


            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default View;
