import React, {useEffect, useState} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import { AuthContext, useAuth } from "./contexts/Auth";
import { PageContext, usePage } from "./contexts/Page";
import Router from "./routes/Router";
import "./styles/Main.css";
import General from "./utils/General";
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Drawer from '@mui/material/Drawer';
import Tooltip from "@mui/material/Tooltip/Tooltip";
import UserApi from "./services/UserApi";
import AlertAccountActivation from "./components/AlertAccountActivation/AlertAccountActivation";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import Menu from "./components/Menu/Menu";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotifyApi from "./services/NotifyApi";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

//--------------------------------------------------
function App(props)
{
    // user auth
    const auth = useAuth();
    const uPage = usePage();
    const getUser = auth.getUser();
    const [authenticated, setAuthenticated] = useState(auth.checkAuthenticate);
    const [nameFamily, setNameFamily] = useState(getUser !== null ? getUser.fullName : '');
    const [walletCredit, setWalletCredit] = useState(getUser !== null ? getUser.walletCredit : '');
    const authentication = (data) =>
    {
        auth.setAuthenticate(data);
        if(data !== undefined && data !== null) setNameFamily(data.user.fullName);
        if(data !== undefined && data !== null) setWalletCredit(data.user.walletCredit);
        setAuthenticated(data !== undefined && data !== null);
    };

    //
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    // page data :: title & path
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState("");
    const [pageTitle2, setPageTitle2] = useState("");
    const [pageName, setPageName] = useState("");
    const [pagePath, setPagePath] = useState("");
    const [activeMenu, setActiveMenu] = useState("");
    const pageData = (data) =>
    {
        uPage.pageTitle = data.title;
        uPage.pageTitle2 = data.title2;
        uPage.pageName = data.pageName;
        setPageTitle(data.title);
        setPageTitle2(data.title2);
        setPageName(data.pageName);
        setPagePath(data.path);
        setActiveMenu(data.activeMenu);

        auth.token();
    };

    // notify
    let notifyTimeout = null;
    const [notifyTickets, setNotifyTickets] = useState(0);

    const loadNotifyData = () =>
    {
        if(auth.checkAuthenticate())
        {
            // get data
            NotifyApi.notify().then
            (
                function(response)
                {
                    if (response.status === 200)
                    {
                        setWalletCredit(parseInt(response.data.wallet));
                        setNotifyTickets(parseInt(response.data.tickets));
                    }
                }
            ).catch
            (
                function(error)
                {
                }
            );

            // repeat
            notifyTimeout = setTimeout(loadNotifyData, 60000);
        }
        else
        {
            clearInterval(notifyTimeout);
        }
    };

    useEffect(() => { notifyTimeout = setTimeout(loadNotifyData, 1000); }, [authenticated]);

    // log out
    function logOut()
    {
        UserApi.logout();
        authentication(null);
    }

    //
    return (
        <AuthContext.Provider value={{ authenticated: authenticated, authentication: authentication }}>
            <PageContext.Provider value={{ pageData: pageData }}>
                <GoogleReCaptchaProvider reCaptchaKey={General.reCaptchaKey}>
                    {
                        authenticated ?
                            (
                                <div className={pageName !== "" ? "main-body main-body-" + pageName : "main-body"}>
                                    <Menu activeMenu={activeMenu} logOut={logOut} />

                                    <div className="col-content">
                                        <div className="top-bar">
                                            <div className="menu-drawer-user-name-family">
                                                <MenuIcon className="btn-menu-drawer" onClick={() => setIsOpenDrawer(true)} />
                                                <span className="user-name-family">Hiya {nameFamily}</span>
                                            </div>

                                            <div className="wallet-logout">
                                                <Tooltip title="Wallet" placement="bottom" arrow><Link to="/wallet" className="btn-wallet">{walletCredit > 0 ? '$' + walletCredit : ''}<AccountBalanceWalletOutlinedIcon className="icon" /></Link></Tooltip>
                                                <Tooltip title="Tickets" placement="bottom" arrow><IconButton component={Link} to="/tickets"><Badge badgeContent={notifyTickets} invisible={notifyTickets <= 0} color="error"><ForumOutlinedIcon className="icon" /></Badge></IconButton></Tooltip>

                                                <Tooltip title="Logout" placement="bottom" arrow><LogoutIcon className="icon" onClick={logOut} /></Tooltip>
                                            </div>
                                        </div>

                                        <AlertAccountActivation />

                                        <Router />
                                    </div>

                                    <Drawer className="menu-drawer" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
                                        <Menu activeMenu={activeMenu} logOut={logOut} />
                                    </Drawer>
                                </div>
                            )
                            :
                            (
                                <div className="main-body">
                                    <Router />
                                </div>
                            )
                    }
                </GoogleReCaptchaProvider>
            </PageContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
