import React, {useRef, useState} from "react";
import General from "../../utils/General";
import Alert from "@mui/material/Alert/Alert";
import AlertTitle from "@mui/material/AlertTitle/AlertTitle";
import Button from "@mui/material/Button";
import "./AlertAccountActivation.css";
import UserApi from "../../services/UserApi";
import LoadingOver from "../LoadingOver/LoadingOver";
import AlertAction from "../AlertAction";
import {Scrollbars} from "react-custom-scrollbars-2";

//
function AlertAccountActivation(prop)
{
    const [formLoading, setFormLoading] = useState(false);

    const alertAction = useRef(); // init alert

    // send activation link
    const sendActivationLink = () =>
    {
        setFormLoading(true); // show loading

        UserApi.confirm().then
        (
            function(response)
            {
                if(response.status === 200)
                {
                    alertAction.current.showAlert("Activation Link Sent", "success"); // show success
                }
                else
                {
                    alertAction.current.showAlert(response.message); // show error
                }

                setFormLoading(false); // show btn & hide loading
            }
        ).catch
        (
            function(error)
            {
                setFormLoading(false); // show btn & hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return(
        <>
            {
                General.user !== null && !General.user.isActive ?
                    <div className="alert-account-activation">
                        <Alert severity="error" variant="filled">
                            <AlertTitle>Your account is currently inactive.</AlertTitle>
                            <div className="alert-des">You cannot make any purchases until you activate your account using the activation link sent to your email. Please check your spam folder if you haven't yet received an activation mail.</div>

                            <Button color="primary" variant="text" onClick={sendActivationLink}>Click here to send another activation link.</Button>
                        </Alert>
                    </div>
                    :
                    null
            }

            {/* loading */}
            {formLoading ? <LoadingOver /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </>
    )
}
export default AlertAccountActivation;
