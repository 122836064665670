import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useNavigate, useParams} from "react-router-dom";
import SortTable from "../../components/SortTable/SortTable";
import Pagination from "../../components/Pagination/Pagination";
import TicketApi from "../../services/TicketApi";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import General from "../../utils/General";
import IconButton from '@mui/material/IconButton';
import {Button, Container, Grid, TextField} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import DialogQuestion from "../../components/DialogQuestion/DialogQuestion";
import AlertAction from "../../components/AlertAction";
import "./Tickets.css";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

//--------------------------------------------------

function Tickets(props)
{
    // set page title & page path
    const pageTitle = Menus.tickets.title;
    const pageClass = Menus.tickets.className;
    usePage().pageData({title: pageTitle, activeMenu: pageClass});

    //
    const [ticketData, setTicketData] = useState([]);
    const [ticketDataLoading, setTicketDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const [ticketId, setTicketId] = useState('');
    const [searchWord, setSearchWord] = useState('');

    const navigate = useNavigate();
    const alertAction = useRef(); // init alert
    const dialogQuestion = useRef(); // init alert delete

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setTicketData(null); // set data to null
        setTicketDataLoading(true); // show loading

        // get data
        TicketApi.find({ pageNumber: pageNumber, sortColName: sortColName, sortType: sortType, searchWord: searchWord }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setTicketData(response.data.list);
                }

                setTicketDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setTicketDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { setSearchWord(''); loadData(); }, [sortColName, sortType]);

    // search
    const handleSearch = (e) =>
    {
        loadData();
    };

    //
    const changeSortTable = (colName) =>
    {
        let currentSortType = sortData[colName];
        let sd =  {};

        sd[colName] = currentSortType === 'asc' ? 'desc' : 'asc';

        setSortData(sd);
        setSortColName(colName);
        setSortType(sd[colName]);
    };

    //
    const handleTicketClose = (id) =>
    {
        dialogQuestion.current.show();

        setTicketId(id); // set ticket id for delete
    };

    const ticketClose = () =>
    {
        dialogQuestion.current.hide(); // hide popup
        setTicketDataLoading(true); // show loading

        // get data
        TicketApi.close({ ticketId: ticketId }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    alertAction.current.showAlert("Ticket Closed", "success"); // show success

                    let tmpData = ticketData;
                    tmpData[0].isClosed = true;
                    setTicketData([...tmpData, ...[]]);
                }
                else
                {
                    alertAction.current.showAlert(response.message); // show error
                }

                setTicketDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setTicketDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    const goToTicketView = (id) =>
    {
        navigate("/ticket/view/" + id, { replace: false });
    };

    //
    return (
        <div className="page-content page-ticket">
            <div className="title-bar-breadcrumbs-search">
                <div className="title-bar-breadcrumbs">
                    <div className="title-bar">
                        <span className="title">{pageTitle}</span>
                    </div>

                    <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Link className="link" underline="hover" color="inherit" to="/">Home</Link>
                        <Typography className="current-page" key="3" color="text.primary">{pageTitle}</Typography>,
                    </Breadcrumbs>
                </div>

                <div className="search-box form-box">
                     <TextField placeholder="Search" className="form-input" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
                    <SearchOutlinedIcon className="icon" onClick={handleSearch} />
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    ticketDataLoading ?
                        null
                        :
                        (
                            ticketData !== null && ticketData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th>Subject</th>
                                        <th onClick={() => changeSortTable('status')} className="w-140"><span className="col-value"><SortTable sortData={sortData} colName="status" />Status</span></th>
                                        <th onClick={() => changeSortTable('dateCreated')} className="w-180"><span className="col-value"><SortTable sortData={sortData} colName="dateCreated" />Date Created</span></th>
                                        <th onClick={() => changeSortTable('dateUpdated')} className="w-180"><span className="col-value"><SortTable sortData={sortData} colName="dateUpdated" />Date Updated</span></th>
                                        <th className="w-80">Action</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        ticketData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index} className={item.isNew ? 'tr-new' : ''}>
                                                        <td>{item.subject}</td>
                                                        <td><label className={'label-status ' + General.initStatusClass(item.statusTitle)}>{item.statusTitle}</label></td>
                                                        <td>{item.dateCreated}</td>
                                                        <td>{item.dateUpdated}</td>
                                                        <td>
                                                            {!item.isClosed ? <Tooltip title="Close Ticket" placement="top" arrow><IconButton color="error" onClick={() => handleTicketClose(item.id)}><LockOutlinedIcon /></IconButton></Tooltip> : null}
                                                            <Tooltip title="View Ticket" placement="top" arrow><IconButton color="primary" onClick={() => goToTicketView(item.id)}><RemoveRedEyeOutlinedIcon /></IconButton></Tooltip>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    <tfoot className="sticky">
                                    <tr>
                                        <td colSpan="10"><Pagination data={paginationData} loadData={loadData} /></td>
                                    </tr>
                                    </tfoot>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }
            </Scrollbars>


            {/* loading */}
            {ticketDataLoading ? <LoadingOver /> : null}


            {/* alert */}
            <AlertAction ref={alertAction} />


            {/* popup close */}
            <DialogQuestion ref={dialogQuestion} accept={ticketClose} title="Close Ticket" des="Are you sure you want to close the ticket?" buttonType="error" buttonAcceptText="Yes, Close Ticket" buttonCloseText="No" />
        </div>
    );
}

export default Tickets;
