import axios from "axios";
import General from "../utils/General";
import qs from "qs";
import React from "react";

export default
{
    rest: async function(path, params = null, isAuthorization = true)
    {
        let res = '';
        let config = (isAuthorization) ? {headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded"}} : {headers: {"content-type": "application/x-www-form-urlencoded"}};
        params = params != null ? qs.stringify(params) : null;

        try
        {
            const response = await axios.post(General.apiUrl + path, params, config);

            res = response.data;
        }
        catch(error)
        {
            if(error.response.status === 401)
            {
                localStorage.setItem(General.localStorageName, JSON.stringify(null));
                window.location.href = '/login';
            }

            res = error.response.data;
        }

        return res;
    },

    restUpload: async function(path, params = null, isAuthorization = true)
    {
        let res = '';
        let config = (isAuthorization) ? {headers: {...General.authorizationToken, "content-type": "multipart/form-data"}} : {headers: {"content-type": "multipart/form-data"}};

        try
        {
            const response = await axios.post(General.apiUrl + path, params, config);

            res = response.data;
        }
        catch(error)
        {
            if(error.response.status === 401)
            {
                localStorage.setItem(General.localStorageName, JSON.stringify(null));
                window.location.href = '/login';
            }

            res = error.response.data;
        }

        return res;
    },

    restBuffer: async function(path, params = null, isAuthorization = true)
    {
        let res = '';
        let config = (isAuthorization) ? {headers: {...General.authorizationToken, "content-type": "application/x-www-form-urlencoded", responseType: 'arraybuffer'}, responseType: 'arraybuffer'} : {headers: {"content-type": "application/x-www-form-urlencoded"}, responseType: 'arraybuffer'};
        params = params != null ? qs.stringify(params) : null;

        try
        {
            const response = await axios.post(General.apiUrl + path, params, config);

            res = response.data;
        }
        catch(error)
        {
            if(error.response.status === 401)
            {
                localStorage.setItem(General.localStorageName, JSON.stringify(null));
                window.location.href = '/login';
            }

            res = error.response.data;
        }

        return res;
    },
}
